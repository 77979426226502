import React, { Component } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Formik } from "formik";
import { ReportService } from "../../service/ReportService";
import CasMessage from "../common/messages/CasMessage";
import { AuthService } from "../../service/AuthService";
import { withTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Carousel } from "primereact/carousel";
import { InputText } from "primereact/inputtext";
import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import CasButton from "../common/formfields/CasButton";
import FailureDialog from "../common/dialogue/FailureDialog";
import SuccessDialog from "../common/dialogue/SuccessDialog";
import config from "../../config/url.json";
import Tooltip from "../common/tooltip/Tooltip";

const initialData = {
  testenvironment: "",
  testschedule: "",
  selectedmodulename: [{ id: -1, code: "ALL" }],
};
/* Cancellation Reason Approval Page */
class RapidTestReport extends Component {
  constructor(props) {
    super(props);
    const data = JSON.parse(JSON.stringify(initialData));
    this.state = {
      airportData: [],
      searchTableData: [],
      selectedData: [],
      globalFilter: "",
      reasonCommon: null,
      failedRow: { verificationResult: [] },
      link: "",
      loading: false,
      oldResults: false,
      fullData: false,
      resultsDisplayed: false,
      showSearchMessage: undefined,
      hasSheduleId: false,
      showReportErrorDialog: false,
      data,
    };
    this.cancelReasonOptions = [
      { id: "1", code: "Bad Weather" },
      { id: "2", code: "Mechanical Issues" },
      { id: "3", code: "Security Issues" },
      { id: "4", code: "Bird Strikes" },
      { id: "5", code: "Missing Crew" },
      { id: "6", code: "Computer Glitch" },
    ];

    this.reportParam = {};
    this.renderColumns = this.renderColumns.bind(this);
    this.getEnviormentDetails = this.getEnviormentDetails.bind(this);
    this.enviormentChange = this.enviormentChange.bind(this);
    this.schedulerChange = this.schedulerChange.bind(this);
    this.moduleChange = this.moduleChange.bind(this);
    this.searchReportResults = this.searchReportResults.bind(this);
    this.patternChange = this.patternChange.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.statusTemplate = this.statusTemplate.bind(this);
    this.handleFailedCase = this.handleFailedCase.bind(this);
    this.screenShotTemplate = this.screenShotTemplate.bind(this);
    this.viewScreenShot = this.viewScreenShot.bind(this);
    this.handleChangeRes = this.handleChangeRes.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.removeMultiSelectHeader = this.removeMultiSelectHeader.bind(this);
  }

  /**
   * Function to remove Header row fromn MultiSelect Dropdown
   */
  removeMultiSelectHeader = () => {
    const headerDiv = document.querySelector(".p-multiselect-header");
    if (headerDiv) {
      headerDiv.remove();
    }
  };

  toggleChange = () => {
    this.setState({
      oldResults: !this.state.oldResults,
      resultsDisplayed: false,
    });
    this.patternList = [{ id: -1, code: "ALL" }];
    this.schedulerList = [];
    this.moduleList = [{ id: -1, code: "ALL" }];
  };

  toggleData = () => {
    this.setState({ fullData: !this.state.fullData, resultsDisplayed: false });
  };

  renderColumns(columns) {
    let headerClassName;
    let columList = [];
    columns.map((col, index) => {
      headerClassName = "report-data-table-header ";
      if (col.headerClassName) {
        headerClassName = headerClassName + col.headerClassName;
      }
      columList.push(
        <Column
          key={`key${index}`}
          field={col.field}
          header={col.header}
          sortable={col.sortable}
          className={col.className}
          headerClassName={headerClassName}
          body={col.body}
          editor={col.editor}
        />
      );
      return 0;
    });
    return columList;
  }

  handleChangeRes(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  getEnviormentDetails() {
    this.enviormentList = [];
    ReportService.getEnviormentDetails().then((res) => {
      res.forEach((item) => {
        this.enviormentList.push({ id: item.appId, code: item.appName });
      });
    });
  }

  componentDidMount() {
    this.getEnviormentDetails();
    this.resetFields();
  }

  resetFields() {
    this.schedulerList = [];
    this.moduleList = [{ id: -1, code: "ALL" }];
    this.patternList = [{ id: -1, code: "ALL" }];
    this.statusList = [
      { id: "ALL", code: "ALL" },
      { id: "SUCCESS", code: "SUCCESS" },
      { id: "FAILED", code: "FAILED" },
      { id: "SKIPPED", code: "SKIPPED" },
    ];
    // this.setModuleChange("selectedmodulename", { "id": -1, "code": "ALL" })
  }

  handleSuccessCase = (successRow) => {
    this.setState({ visibleSuccess: true, successRow: successRow });
  };

  statusTemplate(rowData) {
    if (rowData.status == "SUCCESS") {
      return (
        <React.Fragment>
          <Button
            id="successBtn"
            name="successBtn"
            label={rowData.status}
            onClick={(e) =>
              this.state.fullData
                ? this.handleFailedCase(rowData)
                : this.handleSuccessCase(rowData)
            }
            className="p-button-rounded p-button-success p-mr-2"
          />
        </React.Fragment>
      );
    } else if (rowData.status == "FAILED") {
      return (
        <React.Fragment>
          <Button
            id="fail"
            icon="pi pi-info-circle"
            className="p-button-rounded p-button-danger p-mr-2"
            name="failBtn"
            label={rowData.status}
            onClick={(e) => this.handleFailedCase(rowData)}
          />
        </React.Fragment>
      );
    } else if (rowData.status == "SKIPPED") {
      return (
        <React.Fragment>
          <Button
            id="skip"
            className="p-button-rounded p-button-warning p-mr-2"
            name="skipBtn"
            label={rowData.status}
          />
        </React.Fragment>
      );
    } else if (rowData.status == "ERROR") {
      return (
        <React.Fragment>
          <Button
            id="error"
            className="p-button-rounded p-button-danger p-mr-2"
            name="errorBtn"
            label={rowData.status}
          />
        </React.Fragment>
      );
    }
  }

  handleFailedCase(failedRow) {
    this.setState({ visible: true, failedRow: failedRow });
  }

  /**
   * Function will trigger on clicking the Screenshot Count Column
   * Will Not be triggered incase of No Screenshots
   */
  viewScreenShot = (rowData) => {
    if (rowData.screenShotCount !== 0) {
      this.setState({ loading: true });
      ReportService.getTestScreenShots(rowData.resultId).then((res) => {
        this.setState({ loading: false });
        if (res && res.length > 0) {
          this.screenShotResult = [];
          let count = 1;
          for (let i in res) {
            if (res[i].screenShot != null) {
              let screenShotName;
              if (res[i].screenShotFilePath != null) {
                let filePath = res[i].screenShotFilePath.includes("\\") ? res[i].screenShotFilePath.split("\\") : res[i].screenShotFilePath.split("/");
                screenShotName = filePath[filePath.length - 1];
              }
              this.screenShotResult.push({
                screenshot: "data:image/jpeg;base64," + res[i].screenShot,
                name: screenShotName,
              });
              count++;
            }
          }
          // If there are Screenshots in the response
          if (this.screenShotResult.length > 0) {
            this.setState({ screenShotDlg: true });
          }
        }
      });
    }
  };

  screenShotTemplate(rowData) {
    const isZeroScreenshotCondition = rowData.screenShotCount === 0;
    return (
      <React.Fragment>
        <span
          className="screenshotbox screenshotcount"
          onClick={(e) => this.viewScreenShot(rowData)}
        >
          {isZeroScreenshotCondition ? (
            <Tooltip text="No Screenshots Available">
              <b className="table-screenshot-count">
                {rowData.screenShotCount}
              </b>
            </Tooltip>
          ) : (
            <b className="table-screenshot-count">{rowData.screenShotCount}</b>
          )}
        </span>
      </React.Fragment>
    );
  }

  enviormentChange(setFunction, event) {
    // Setting the ModuleName Dropdown to default value
    this.state.data.selectedmodulename = [{ id: -1, code: "ALL" }];
    if (this.state.showSearchMessage) {
      this.setState({ showSearchMessage: undefined });
    }
    this.resetFields();
    this.reportParam.schedulerId = "";
    setFunction(event.target.name, event.target.value);
    if (!event.target.value) {
      this.reportParam.appId = "";
      return;
    }
    this.reportParam.appId = event.target.value && event.target.value.id;
    ReportService.getScheduleDetails(this.reportParam.appId).then((res) => {
      let arr = this.state.oldResults ? res : res.slice(-10);
      arr.forEach((item) => {
        this.schedulerList.unshift({
          id: item.schedularId,
          code: item.schedulerName,
        });
      });
    });
  }

  schedulerChange(setFunction, event) {
    // Setting the ModuleName Dropdown to default value
    this.state.data.selectedmodulename = [{ id: -1, code: "ALL" }];
    if (this.state.showSearchMessage) {
      this.setState({ showSearchMessage: undefined });
    }
    //this.moduleList = [];
    this.moduleList = [{ id: -1, code: "ALL" }];
    if (this.reportParam.patternName) {
      setFunction("patternList", { id: -1, code: "ALL" });
      delete this.reportParam.patternName;
    }
    if (this.reportParam.isModuleName) {
      setFunction("moduleList", { id: -1, code: "ALL" });
      delete this.reportParam.moduleId;
      this.reportParam.isModuleName = false;
    }
    if (this.reportParam.status) {
      setFunction("statusList", { id: "ALL", code: "ALL" });
      delete this.reportParam.status;
    }
    if (!event.target.value) {
      this.setState({ hasSheduleId: true });
    } else {
      this.setState({ hasSheduleId: false });
    }
    setFunction(event.target.name, event.target.value);
    this.reportParam.schedulerId = event.target.value && event.target.value.id;
    ReportService.getModuleDetails(this.reportParam).then((res) => {
      res.forEach((item) => {
        this.reportParam.moduleId = event.target.value && item.moduleId;
        ReportService.getPatternDetails(this.reportParam).then((res) =>
          res.length
            ? this.moduleList.push({ id: item.moduleId, code: item.moduleName })
            : ``
        );
      });
    });

    this.reportParam.moduleId = 0;
    //   .finally(() => {
    //     setTimeout(()=> {
    //       if (this.moduleList.length === 1) {
    //       this.setState({ module: this.moduleList[0] })
    //     }
    //     },1000)
    //   }
    // )
  }

  moduleChange(setFunction, event) {
    if (this.state.showSearchMessage) {
      this.setState({ showSearchMessage: undefined });
    }
    this.patternList = [{ id: -1, code: "ALL" }];
    setFunction(event.target.name, event.target.value);

    this.reportParam.moduleId =
      event.target.value.id == -1 ? 0 : event.target.value.id;
    this.reportParam.isModuleName = true;
    ReportService.getPatternDetails(this.reportParam).then((res) => {
      res.forEach((item) => {
        this.patternList.push({ id: item.patternId, code: item.patternName });
      });
    });
  }
  patternChange(setFunction, event) {
    setFunction(event.target.name, event.target.value);
    this.reportParam.patternName = event.target.value.code;
  }

  statusChange(setFunction, event) {
    setFunction(event.target.name, event.target.value);
    this.reportParam.status = event.target.value.id;
  }

  searchReportResults(event) {
    const tempSelectedModuleIdArray = this.state.data.selectedmodulename;
    this.reportParam.fullData = this.state.fullData;
    this.reportParam.moduleId = tempSelectedModuleIdArray.some(
      (ele) => ele.id === -1
    )
      ? []
      : tempSelectedModuleIdArray.map((ele) => ele.id);
    let rslt;
    if (!this.reportParam.appId) {
      this.setState({
        showSearchMessage: "*Please select Test Environment and Test Schedule",
        resultsDisplayed: false,
      });
    } else if (!this.reportParam.schedulerId || this.state.hasSheduleId) {
      this.setState({
        showSearchMessage: "*Please select Test Schedule",
        resultsDisplayed: false,
      });
    } else {
      this.setState({ loading: true });

      //this.setState({showSearchError:true});

      ReportService.getTestResultDetails(this.reportParam).then((res, er) => {
        if (res.length == undefined) {
          //Throw error
          this.setState(
            {
              showReportErrorDialog: true,
              loading: false,
            },
            () => {
              // this.resetFields();
            }
          );
        } else {
          let showReportErrorDialog;
          let resDisplayed;
          if (res[0] && res[0].resultVo && res[0].resultVo.length < 1) {
            this.setState({
              loading: false,
              showReportErrorDialog: true,
              resultsDisplayed: false,
              searchTableData: res[0].resultVo,
            });
          }
          this.setState({
            loading: false,
            resultsDisplayed: true,
            searchTableData: res[0].resultVo,
          });
          ReportService.getReportBlobData(res[0].reportFilePath).then(
            (result) => {
              const blob = new Blob([result], {
                type: "application/octet-stream",
              });
              this.setState({ link: URL.createObjectURL(blob) });
            }
          );
        }
      });
    }
  }

  /**
   * Function will get triggered on Module Name MultiSelect DropDown Change
   */
  setModuleChange = (setFunction, event, key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState(
      {
        data,
      },
      () => {
        setFunction(event.target.name, event.target.value);
        this.patternList = [{ id: -1, code: "ALL" }];
        let tempModuleID = [0];
        const moduleListArray = event.target.value;
        const isAllIncluded = moduleListArray.some(
          (module) => module.id === -1
        );
        // Logic -> ALL is selected by default.
        // If no selection, ALL is automatically checked.
        // If any other selection than ALL, ALL is automatically unchecked.
        if (moduleListArray.length === 0) {
          this.state.data.selectedmodulename = [{ id: -1, code: "ALL" }];
        } else if (moduleListArray.length > 1 && isAllIncluded) {
          this.state.data.selectedmodulename = moduleListArray.filter(
            (mod) => !(mod.id === -1 && mod.code === "ALL")
          );
        }
        if (!isAllIncluded) {
          tempModuleID = [];
          moduleListArray.forEach((ele) => {
            if (!tempModuleID.includes(ele.id)) {
              tempModuleID.push(ele.id);
            }
          });
        }
        this.reportParam.moduleId = tempModuleID;
        if (moduleListArray.length === 1 && !isAllIncluded) {
          ReportService.getPatternDetails(this.reportParam).then((res) => {
            res.forEach((item) => {
              this.patternList.push({
                id: item.patternId,
                code: item.patternName,
              });
            });
          });
        }
      }
    );
  };

  handleRequestClose = () => {
    this.setState({
      showReportErrorDialog: false,
    });
  };

  render() {
    const { t } = this.props;
    let label = t("flightReasonApproval", { returnObjects: true });
    const override = css`
      display: inline;
      margin: 1.4rem;
    `;
    const dropDownCSS = {
      outline: "0",
      border: "0",
      borderRadius: "3px",
      border: "2px solid #007AD9",
    };

    const multiSelectCSS = {
      width: "100%",
      borderRadius: "3px",
      border: "2px solid #007AD9",
    };

    const responsiveOptions = [
      {
        breakpoint: "480px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
    const deleteDialogFooter = (
      <React.Fragment>
        <CasButton
          label="Close"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.handleRequestClose}
        />
      </React.Fragment>
    );
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={(e) => this.setState({ visible: false })}
        />
        <Button
          label="No"
          icon="pi pi-times"
          onClick={(e) => this.setState({ visible: false })}
          className="p-button-secondary"
        />
      </div>
    );

    const renderHeader = (
      <div className="table-header">
        <div
          className="p-grid"
          style={{
            justifyContent: "space-between",
            margin: "0.4rem 0.4rem 0.4rem 0.1rem",
          }}
        >
          <div>
            <span>
              <h2>List of Results</h2>
            </span>
          </div>
          <div>
            <span className="p-input-icon-right float-right">
              <i className="pi pi-search" />
              <InputText
                onChange={this.handleChangeRes}
                name="globalFilter"
                placeholder={label.table.globalSearch}
                value={this.state.globalFilter}
                disabled={!this.state.resultsDisplayed}
              />
            </span>
          </div>
          <div>
            <Button
              className="downloadbtn-header"
              disabled={config.securedEnv || !this.state.resultsDisplayed}
            >
              <a
                href={this.state.link}
                download="Test_Report.xlsx"
                target="_blank"
              >
                <i
                  style={{ color: "white", marginRight: "1rem" }}
                  className="pi pi-download"
                ></i>
                <span style={{ color: "white" }}>Download</span>
              </a>
            </Button>
          </div>
        </div>
      </div>
    );

    const setInitialValues = {
      statusList: { id: "ALL", code: "ALL" },
      patternList: { id: -1, code: "ALL" },
      moduleList: { id: -1, code: "ALL" },
      // style={{marginLeft: "1rem"}}
    };

    const screenShotTemplateCaraousel = (result) => {
      return (
        <div className="product-item">
          <div style={{ textAlign: "center", marginBottom: "1rem" }}>
            <h2 style={{ fontWeight: "700" }} className="p-mb-1">
              Test Case : {result.name}
            </h2>
          </div>
          <div className="product-item-content">
            <div className="p-mb-3">
              <img
                src={result.screenshot}
                alt="carousel-item"
                className="product-image"
              />
            </div>
          </div>
        </div>
      );
    };

    const paginatorLeft = (
      <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
      <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    let renderColumns = [
      {
        field: "testCaseReportId",
        header: label.table.testCaseId,
        headerClassName: "testCaseId-header",
      },
      {
        field: "testCaseName",
        header: label.table.testCasename,
        sortable: true,
        headerClassName: "testCasename-header",
      },
      {
        field: "moduleName",
        header: label.table.moduleName,
        sortable: true,
        headerClassName: "moduleName-header",
      },
      {
        field: "pattern",
        header: label.table.pattern,
        sortable: true,
        headerClassName: "pattern-header",
      },
      {
        field: "starttime",
        header: label.table.starttime,
        sortable: true,
        headerClassName: "starttime-header",
      },
      {
        field: "endtime",
        header: label.table.endtime,
        sortable: true,
        headerClassName: "endtime-header",
      },
      {
        field: "total_time_taken",
        header: label.table.totaltimetaken,
        sortable: true,
        headerClassName: "totaltimetaken-header",
      },
      {
        field: "status",
        header: label.table.status,
        sortable: true,
        body: this.statusTemplate,
        headerClassName: "status-header",
      },
      {
        field: "screenShotCount",
        header: label.table.screenshot,
        sortable: true,
        headerClassName: "screenshot-header",
        body: this.screenShotTemplate,
      },
    ];

    const { data } = this.state;

    return (
      <>
        {
          <Dialog
            className="cas-dialog"
            visible={this.state.showReportErrorDialog}
            style={{ width: "30rem" }}
            header="Message"
            modal={true}
            footer={deleteDialogFooter}
            onHide={() => this.handleRequestClose()}
          >
            <div style={{ display: "flex" }} className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              <span
                style={{ marginTop: "5px" }}
              >{`No test result found due to incomplete Test execution.`}</span>
            </div>
          </Dialog>
        }
        <div className="p-grid form-container">
          <CasMessage
            passRef={(el) => (this.messages = el)}
            className="forms-message"
          ></CasMessage>
          <div className="p-col-12">
            <div className="card card-w-title">
              <div className="p-grid">
                <div className="p-col-12">
                  <span>
                    <h1>{label.headings.formHeading}</h1>{" "}
                  </span>
                </div>
              </div>
              <Formik
                initialValues={setInitialValues}
                validate={this.validateCancelForm}
                onSubmit={this.searchRecords}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  resetForm,
                }) => (
                  <div>
                    <div
                      style={{ fontSize: "1.15rem" }}
                      className="p-grid p-formgrid"
                    >
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        {label.forms.testEnviorment}
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        {label.forms.testSchedule}
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        {label.forms.moduleName}
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        {label.forms.dataPattern}
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        {label.forms.status}
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={this.state.oldResults}
                            onChange={resetForm}
                            onClick={this.toggleChange}
                            style={{
                              marginBottom: "0.5rem",
                              marginRight: "0.4rem",
                            }}
                          />
                          Load Older Results
                        </label>
                      </div>
                    </div>
                    <div className="p-grid p-formgrid">
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        <Dropdown
                          id="testEnviorment"
                          name="testEnviorment"
                          options={this.enviormentList}
                          optionLabel="code"
                          value={values.testEnviorment}
                          filter
                          showClear
                          style={dropDownCSS}
                          errorText=""
                          placeholder={label.forms.pleaseSelect}
                          onChange={(e) =>
                            this.enviormentChange(setFieldValue, e)
                          }
                        />
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        <Dropdown
                          id="schedulerList"
                          name="schedulerList"
                          value={values.schedulerList}
                          options={this.schedulerList}
                          onChange={(e) =>
                            this.schedulerChange(setFieldValue, e)
                          }
                          optionLabel="code"
                          filter
                          showClear
                          style={dropDownCSS}
                          filterBy="code"
                          placeholder={label.forms.pleaseSelect}
                        />
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        <MultiSelect
                          id="moduleList"
                          name="moduleList"
                          className="modulelist"
                          value={data.selectedmodulename}
                          onChange={(e) =>
                            this.setModuleChange(
                              setFieldValue,
                              e,
                              "selectedmodulename",
                              e.value
                            )
                          }
                          options={this.moduleList || []}
                          optionLabel="code"
                          style={multiSelectCSS}
                          errorText={errors.flightType}
                          placeholder={label.forms.pleaseSelect}
                          onFocus={this.removeMultiSelectHeader}
                        />
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        <Dropdown
                          id="patternList"
                          style={dropDownCSS}
                          name="patternList"
                          options={this.patternList}
                          optionLabel="code"
                          value={values.patternList}
                          errorText={errors.flightType}
                          placeholder={label.forms.pleaseSelect}
                          onChange={(e) => this.patternChange(setFieldValue, e)}
                        />
                      </div>
                      <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        <Dropdown
                          id="statusList"
                          name="statusList"
                          options={this.statusList}
                          optionLabel="code"
                          style={dropDownCSS}
                          value={values.statusList}
                          errorText={errors.status}
                          onChange={(e) => this.statusChange(setFieldValue, e)}
                        />
                      </div>
                      <div
                        className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0"
                        style={{ fontSize: "1.15rem" }}
                      >
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={this.state.fullData}
                            onClick={this.toggleData}
                            style={{
                              marginBottom: "0.8rem",
                              marginRight: "0.4rem",
                            }}
                          />
                          Load Full Data
                        </label>
                        <br />
                        <Button
                          label={label.forms.search}
                          icon="pi pi-search"
                          onClick={this.searchReportResults}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Formik>

              <div className="p-col-12 cancelation-results-container">
                <div className="card-w-title">
                  <div className="p-grid">
                    <div className="sweet-loading p-col-8">
                      {this.state.showSearchMessage && (
                        <div
                          style={{
                            color: "red",
                            position: "fixed",
                            marginTop: "10px",
                          }}
                        >
                          {this.state.showSearchMessage}
                        </div>
                      )}
                      <div style={{ marginLeft: "71%", marginTop: "0.2rem" }}>
                        <PropagateLoader
                          color="#00aaad"
                          size={22}
                          loading={this.state.loading}
                          css={override}
                        />
                      </div>
                    </div>
                  </div>
                  {console.log(this.state.searchTableData, "searchTableData")}
                  {this.state.resultsDisplayed ? (
                    <DataTable
                      value={this.state.searchTableData}
                      selection={this.state.selectedData}
                      rows={10}
                      header={renderHeader}
                      columnResizeMode="expand"
                      editMode="cell"
                      rowHover={true}
                      showSelection={AuthService.getPrivileges().includes(
                        "CP010"
                      )}
                      selectionMode={
                        AuthService.getPrivileges().includes("CP010")
                          ? "multiple"
                          : "single"
                      }
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      paginatorLeft={paginatorLeft}
                      paginatorRight={paginatorRight}
                      globalFilter={this.state.globalFilter}
                      onSelectionChange={(e) =>
                        this.setState({ selectedData: e.value })
                      }
                      columns={renderColumns}
                    >
                      {this.renderColumns(renderColumns)}
                    </DataTable>
                  ) : (
                    ``
                  )}
                  <FailureDialog
                    vresult={this.state.failedRow.verificationResult}
                    visible={this.state.visible}
                    closeDialog={(e) => this.setState({ visible: false })}
                  />
                  <SuccessDialog
                    vresult={
                      this.state.successRow &&
                      this.state.successRow.verificationResult
                    }
                    visible={this.state.visibleSuccess}
                    closeDialog={(e) =>
                      this.setState({ visibleSuccess: false })
                    }
                  />

                  {/* <Dialog
                    header="Output"
                    visible={this.state.visibleSuccess}
                    style={{ width: '50vw', wordBreak: "break-word", whiteSpace: "break-spaces" }}
                    maximizable={true}
                    onHide={() => this.setState({ visibleSuccess: false })}>
                    <table class="remarkstable">
                      <thead style={{ position: "sticky", top: '-15px', background: '#b3c6ff' }}>
                        <tr >
                          <th colspan="3" >Actual</th>
                          <th colspan="3">Expected</th>
                        </tr>
                      </thead>
                      <tbody class="tableBody">
                        {
                          this.state.successRow &&
                          this.state.successRow.verificationResult &&
                          this.state.successRow.verificationResult.length > 0 &&
                          this.state.successRow.verificationResult.map((type, index) => (

                            <>

                              <tr>
                                <td>{type.message}</td>
                                <td>testing</td>
                              </tr>
                            </>
                          ))
                        }

                      </tbody>
                    </table>
                  </Dialog> */}

                  <Dialog
                    id="screenShotDlg"
                    header="Screen shots"
                    visible={this.state.screenShotDlg}
                    style={{ width: "90vw" }}
                    modal={true}
                    onHide={(e) => this.setState({ screenShotDlg: false })}
                    maximizable={true}
                  >
                    <div className="card">
                      <Carousel
                        value={this.screenShotResult}
                        numVisible={1}
                        numScroll={1}
                        responsiveOptions={responsiveOptions}
                        itemTemplate={screenShotTemplateCaraousel}
                      />
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("translations")(RapidTestReport);

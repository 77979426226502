import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';
import { ReExecutionService } from '../../service/ReExecutionService.js';
import { Toast } from "primereact/toast";
import { InputText } from 'primereact/inputtext';
import CasButton from "../common/formfields/CasButton";
import FilterDropDown from "../common/formfields/FilterDropDown.jsx";
import { Dialog } from "primereact/dialog";
import { PropagateLoader } from 'react-spinners';
import { css } from "@emotion/react";
import { ReportService } from "../../service/ReportService";

class DeleteScheduler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            environmentList: [],
            selectedAppId: undefined,
            schedulerList: [],
            loading: false,
            selectedScheduleList: [],
            showDeleteDialog: false,
            showSearchMessage: false,
            loading: false,
            globalFilter: ""
        }
    }
    componentDidMount = () => {
        this.getEnviormentDetails();
    }

    getEnviormentDetails = () => {
        let environmentList = [];
        ReExecutionService.fetchReExecuteAppDetails().then((res) => {
            res.forEach((item) => {
                environmentList.push({ value: item.appId, label: item.appName });
            })
        })
        this.setState({
            environmentList
        })
    }

    handleAppChange = (key, value) => {
        this.setState({
            [key]: value,
            schedulerList: [],
            selectedScheduleList: [],
            showSearchMessage: undefined
        })
    }

    searchLog = () => {
        const { selectedAppId } = this.state
        if (selectedAppId == undefined || selectedAppId == null) {
            this.setState({
                showSearchMessage: "*Please select the application"
            })
            return
        }
        this.setState({
            loading: true
        })
        let schedulerList = []
        ReportService.getScheduleDetails(selectedAppId).then(
            (res) => {
                res.forEach((item) => {
                    schedulerList.unshift({"schedularId": item.schedularId, "schedulerName": item.schedulerName, "endTime": item.endTime})
                })
                this.setState({
                    schedulerList,
                    loading: false
                })
            })

    }

    getColumnDetails = (columnList) => columnList.map(element => {
        return <Column key={element.field} field={element.field} header={element.header} />
    });

    getActionBody = (selectedRowDetails) => {
        return <div className="field-checkbox" style={{ marginLeft: '20px' }}>
            <Checkbox inputId={selectedRowDetails.schedularId}
                name="city"
                value={selectedRowDetails.schedularId}
                onChange={this.onScheduleChange}
                checked={this.state.selectedScheduleList.indexOf(selectedRowDetails.schedularId) !== -1}
            />
        </div>
    }

    onScheduleChange = (e) => {
        let scheduleIds = [...this.state.selectedScheduleList]
        if (e.checked) {
            scheduleIds.push(e.value)
        } else {
            scheduleIds.splice(scheduleIds.indexOf(e.value), 1)
        }
        this.setState({
            selectedScheduleList: scheduleIds
        })
    }

    deleteSchedule = () => {
        this.setState({
            showDeleteDialog: true
        })
    }

    callDeleteSchedule = () => {
        const { selectedScheduleList, selectedAppId } = this.state
        this.setState({
            loading: true,
            showDeleteDialog: false
        })
        ReExecutionService.deleteSchedulerDetails(selectedAppId, selectedScheduleList).then(
            (res) => {
                if (res) {
                    this.searchLog();
                    this.toast && this.toast.show({
                        severity: "success",
                        summary: "Success",
                        detail: "Selected schedulers are deleted successfully.",
                        life: 3000,
                    });
                    this.setState({
                        selectedScheduleList: [],
                        loading: false
                    })
                }else{
                    this.searchLog();
                    this.toast && this.toast.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Unable to delete the scheduler. Internal Error!",
                        life: 3000,
                    });
                    this.setState({
                        selectedScheduleList: [],
                        loading: false
                    })
                }
            }
        )
    }

    handleChangeRes = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleRequestClose = () => {
        this.setState({
            showDeleteDialog: false
        });
    };

    render() {

        const dropDownCSS = {
            outline: "0",
            border: "0",
            borderRadius: "3px",
            border: "2px solid #007AD9",
        }
        const renderHeader = (
            <div className="table-header">
                <div className="p-grid" style={{ justifyContent: "space-between", margin: "0.4rem 0.4rem 0.4rem 0.1rem" }}>
                    <div>
                        <span><h2>List of Results</h2></span>
                    </div>
                    <div>
                        <span className="p-input-icon-right float-right">
                            <i className="pi pi-search" />
                            <InputText
                                onChange={this.handleChangeRes}
                                name="globalFilter"
                                placeholder="Search"
                                value={this.state.globalFilter}
                                disabled={!this.state.selectedScheduleList}
                            />
                        </span>
                    </div>
                    <div>
                        <Button
                            className="p-button"
                            label="Delete"
                            onClick={() => this.deleteSchedule()}
                            disabled={this.state.selectedScheduleList &&
                                this.state.selectedScheduleList.length > 0 ?
                                false : true
                            }
                        > <i style={{ marginLeft: "1rem" }} class="pi pi-trash"></i> </Button>
                    </div>
                </div>
            </div>
        );
        const deleteDialogFooter = (
            <React.Fragment>
                <CasButton
                    label="No"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.handleRequestClose}
                />
                <CasButton
                    label="Yes"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.callDeleteSchedule}
                />
            </React.Fragment>
        );

        const columnList = [
            { field: 'schedularId', header: 'Scheduler Id' },
            { field: 'schedulerName', header: 'Scheduler Name' },
            { field: 'endTime', header: 'Scheduler Endtime' }
        ];

        const override = css`display: inline;
        margin: 1.4rem;
        `;

        const { selectedAppId, environmentList } = this.state

        return <div className="p-col-12">

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '15px' }}>
                <h3>Delete scheduler</h3>

            </div>
            <div className="p-grid p-formgrid">
                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                    <FilterDropDown
                        label="Application"
                        placeholder="Select the Application"
                        options={environmentList}
                        isRequired={true}
                        id="selectedAppId"
                        value={selectedAppId}
                        onChange={(e) => this.handleAppChange("selectedAppId", e)}
                        testIdentifier="selectedPoints"
                        style={dropDownCSS}
                        info=""
                        enabled={true}
                    />
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                        <Button
                            label={"Search"}
                            icon="pi pi-search"
                            onClick={this.searchLog}
                        />
                    </div>
                </div>
            </div>
            {
                this.state.showSearchMessage &&
                <div style={{ color: "red", display: 'flex', marginTop: "10px" }}>
                    {this.state.showSearchMessage}
                </div>
            }
            <div style={{ marginLeft: "45%", marginBottom: "8px", marginTop: "0.2rem" }}>
                <PropagateLoader color="#00aaad" size={22} loading={this.state.loading} css={override} />
            </div>
            {
                this.state.schedulerList &&
                this.state.schedulerList.length > 0 &&
                <DataTable
                    value={this.state.schedulerList}
                    rows={10}
                    editMode="cell"
                    header={renderHeader}
                    rowHover={true}
                    paginator={true}
                    globalFilter={this.state.globalFilter}
                >
                    <Column
                        header="Action"
                        headerStyle={{ width: '15rem' }}
                        body={(data) => this.getActionBody(data)}
                    />
                    {this.getColumnDetails(columnList)}

                </DataTable>
            }
            {this.state.showDeleteDialog &&
                <Dialog
                    className="cas-dialog"
                    visible={this.state.showDeleteDialog}
                    style={{ width: "30rem" }}
                    header="Confirm"
                    modal={true}
                    footer={deleteDialogFooter}
                    onHide={() => this.handleRequestClose()}
                >
                    <div style={{ display: "flex" }} className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: "2rem" }}
                        />
                        <span style={{ marginTop: "5px" }}>{`Are you sure you want to delete the selected shedulers ?`}</span>
                    </div>
                </Dialog>
            }
            <Toast ref={(el) => (this.toast = el)} />
        </div>
    }
}

export default withTranslation("translations")(DeleteScheduler);
import React from "react";
import { withTranslation } from "react-i18next";
import ReExecute from './ReExecute';
import LogInfo from './LogInfo';
import { TabView, TabPanel } from 'primereact/tabview';
import DeleteScheduler from './DeleteScheduler';
import config from '../../config/url.json'

class ReExecuteApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return <>
            {
                config && config.localEnvironment ?
                    <TabView>
                        <TabPanel header="Delete Scheduler">
                            <DeleteScheduler />
                        </TabPanel>
                    </TabView> :
                    <>
                        <TabView>
                            <TabPanel header="Log Information" >
                                <LogInfo />
                            </TabPanel>
                            <TabPanel header="Re-Execute">
                                <ReExecute />
                            </TabPanel>
                            <TabPanel header="Delete Scheduler">
                                <DeleteScheduler />
                            </TabPanel>
                        </TabView>
                    </>
            }
        </>
    }

}

export default withTranslation("translations")(ReExecuteApplication);
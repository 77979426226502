export const ApplicationConstants = {
    CAS_SESSION_TIME:300000,/* 5 minutes */
    CAS_LOGIN_STAT:"casLoginStat",
    CAS_USER_TOKEN: "casUserToken",
    INVALID_TOKEN:"InvalidToken",
    USER_NAME_KEY:"sub",
    USER_ROLES_KEY:"roles",
    USER_PRIVILEGES_KEY:"privileges",
    NAME_KEY:"name",
    NAME_KEY_JP:"nameJP"
  };
import axios from "axios";
import { ErrorConstants } from "../constants/constants";
import { AuthService } from "./AuthService";
import {APIConstants} from "../constants/APIConstants"

export const UserService = {
  createUserDetailService,
  updateUserDetailService,
  fetchUserDetails,
  deleteUserDetails
};

function createUserDetailService(params) {
  AuthService.setupAxiosInterceptor();
  return (
    axios
      .post(`${APIConstants.ADD_USER}`, params)
        .then((res) => {
          if (res) {
            return Promise.resolve(res.data);
          } else {
            let error = [ErrorConstants.ADD_USER_ERR];
            return Promise.reject(error);
          }
        })
        .catch((err) => {
          let error = [ErrorConstants.ADD_USER_ERR];
          return Promise.reject(error);
        })
  );
}

function updateUserDetailService(params) {
  return (
    axios
      .post(`${APIConstants.UPDATE_USER}`, params)
      .then((res) => {
        if (res) {
          return Promise.resolve(res.data);
        } else {
          let error = [ErrorConstants.UPDATE_USER_ERR];
          return Promise.reject(error);
        }
      })
      .catch((err) => {
        let error = [ErrorConstants.UPDATE_USER_ERR];
        return Promise.reject(error);
      })
  );
}

function deleteUserDetails(userName) {
  AuthService.setupAxiosInterceptor();
  return axios
    .get(`${APIConstants.DELETE_USER}?userName=${userName}`)
      .then((res) => {
        if (res) {
          return Promise.resolve(res.data);
        } else {
          let error = [ErrorConstants.DELETE_USER_ERR];
          return Promise.reject(error);
        }
      })
      .catch((err) => {
        let error = [ErrorConstants.DELETE_USER_ERR];
        return Promise.reject(error);
    });
}

function fetchUserDetails() {
  AuthService.setupAxiosInterceptor();
  return axios
    .get(`${APIConstants.FETCH_ALL_USER}`)
      .then((res) => {
        if (res) {
          return Promise.resolve(res.data);
        } else {
          let error = [ErrorConstants.CAS_GUI_ERR_01];
          return Promise.reject(error);
        }
      })
      .catch((err) => {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      });
}
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import FilterDropDown from "../common/formfields/FilterDropDown.jsx";

class Topbar extends Component {
    constructor(props) {
        super(props);
         this.state = {
      date: new Date()
    };
    }

  componentDidMount() {
     this.timerID = setInterval(
      () => this.tick(),
      1000
    );
     
  };

   tick() {
    this.setState({
      date: new Date()
    });
  }

  componentWillUnmount() {
     clearInterval(this.timerID);
  }

  formatTime(secs) {
    let hours   = Math.floor(secs / 3600);
    let minutes = Math.floor(secs / 60) % 60;
    let seconds = secs % 60;
    return [hours, minutes, seconds]
        .map(v => ('' + v).padStart(2, '0'))
        .filter((v,i) => v !== '00' || i > 0)
        .join(':');
  }

  enviormentChange = (key, value) => {
    this.props.enviormentChange(key, value)
  }

    render() {
      const { options, value } = this.props
      const dropDownCSS = {
        outline: "0",
        border: "0",
        borderRadius: "3px",
        border: "2px solid #007AD9",
    }
        return (
            
            <div>
                <div className="card summary">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="title" style={{ marginTop: "12px" , fontSize: "18px"}}>Dashboard</div>
                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0" style={{ marginLeft: "-180px"}}>
                <FilterDropDown
                    label="Application"
                    placeholder="Select the Application"
                    options={options}
                    isRequired={true}
                    id="selectedApp"
                    value={value}
                    onChange={(e) => this.enviormentChange("selectedEnvironment", e)}
                    testIdentifier="selectedPoints"
                    info=""
                    style={dropDownCSS}
                    enabled={true}
                  />
                </div>
                <div style={{ display: "flex"}}>
                {/* <div style={{ fontSize: "55px", marginTop: "7px", color: "#004164" }}>/</div>
                <div style={{marginLeft: "12px"}}><b> {this.props.environment}</b></div>
                <div style={{marginTop: "21px",marginLeft: "-100px"}}>Application Name</div> */}
                <div style={{ fontSize: "55px", marginTop: "7px" ,marginLeft: "170px", color: "#004164"}}>/</div>
                <div style={{marginLeft: "6px"}}><b>{new Date().toLocaleString("en-US", { day : '2-digit'})}{" "}{new Date().toLocaleString("en-US", { month: "long" })}{" "}{new Date().getFullYear()}</b></div>
                <div style={{marginTop: "18px", marginLeft: "-80px"}}>{this.Date}{this.state.date.toLocaleTimeString()}</div>
                <div style={{ fontSize: "55px", marginTop: "7px", color: "#004164", marginLeft: "7px" }}>/</div>
              </div>
            </div>
          </div>
            </div>
            
        );
    }
}
export default withTranslation("translations")(Topbar);
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import CasButton from "../common/formfields/CasButton";
import CasInputText from "../common/formfields/CasInputText";
import CasPassword from "../common/formfields/CasPassword.jsx";
import { AuthService } from "../../service/AuthService";
import "./LoginScreen.css";
import "../configuration/ToastDemo.css";

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      recoveryPhrase: "",
      password: "",
      confirmPassword: "",
      invalidPhrase: false,
      isValidated: false,
      passwordError: ""
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onPasswordChange = (e) => {
    this.setState({ passwordError: (e.target.value !== this.state.password) ? "Password doesn't match" : "" });
  };

  ValidateDetails = () => {
    AuthService.handleRecoveryPhraseValidation(this.state.userName, this.state.recoveryPhrase)
      .then((res) => {
        if(res) this.setState({ isValidated: res, invalidPhrase: !res });
      })
      .catch((error) => {
          this.setState({ invalidPhrase: true });
      });
  };

  resetPassword = () => {
    if (!this.state.passwordError) {
      AuthService.handlePasswordReset(this.state.userName, this.state.password)
        .then((res) => {
          if (res && res.errorMessage) {
            this.toast.show({
              severity: "error",
              summary: "Error",
              detail: res.errorMessage,
              life: 3000
            });
          } else {
              this.toast.show({
                severity: "success",
                summary: "Success",
                detail: "Password has been reset successfully, Redirecting to the Login Page....",
                life: 6000
              });
              setTimeout(() =>  {window.location.reload();}, 1000)
          }
        },
        (err) => {
          this.toast.show({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000
          });
        })
        .catch((error) => {
          this.toast.show({
            severity: "error",
            summary: "Error",
            detail: "Error",
            life: 3000
          });
        });
    }
  };

  handleBackButton = () => {
    window.location.reload();
  };

  render() {
    return (
      <div className="login-screen">
        <Card
          style={{ width: "27em", border: "0.1px solid #004164" }}
          className="login-screen__card ui-card-shadow"
        >
          <div className="p-grid" style={{ textAlign: 'center' }}>
          <div
            style={{ fontSize: 26, color: '#00aaad' }}
          >
            {this.state.isValidated ? 'Reset Password' : 'Forgot Your Password?'}
          </div>
          {!this.state.isValidated ? 
          <div style={{ marginTop: '30px' }}>
            <div style={{ display: "flex", width: '307px' }} >
              <i style={{ fontSize: "1.5rem", marginRight: '8px', marginTop: '8px' }} className="pi pi-user"/>
              <CasInputText
                id="userName"
                name="userName"
                placeholder="User Name"
                value={this.state.userName}
                onChange={(e) => this.onChange(e)}
                className="p-inputtext-lg p-d-block"
              />
            </div> 
            <div style={{ display: "flex", width: '307px', marginTop: '18px' }} >
              <i style={{ fontSize: "1.5rem", marginRight: '8px', marginTop: '8px' }} className="pi pi-refresh"/>
              <CasInputText
                id="recoveryPhrase"
                name="recoveryPhrase"
                placeholder="Recovery Phrase"
                value={this.state.recoveryPhrase}
                onChange={(e) => this.onChange(e)}
                className="p-inputtext-lg p-d-block"
              />
            </div> 
          </div> :
          <div style={{ marginTop: '30px' }}>
            <div style={{ display: "flex", width: '307px' }} >
              <i style={{ fontSize: "1.5rem", marginRight: '8px', marginTop: '8px' }} className="pi pi-lock"/>
              <CasPassword
                id="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => this.onChange(e)}
                className="p-inputtext-lg p-d-block"
              />
            </div>
            <div style={{ display: "flex", width: '307px', marginTop: '18px' }} >
              <i style={{ fontSize: "1.5rem", marginRight: '8px', marginTop: '8px' }} className="pi pi-check"/>
              <CasPassword
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={this.state.confirmPassword}
                disabled={!this.state.password}
                onChange={(e) => this.onChange(e)}
                onBlur={(e) => this.onPasswordChange(e)}
                className="p-inputtext-lg p-d-block"
                errorText={this.state.passwordError}
              />
            </div>
          </div>
          }
          <div>
            <CasButton
              style={{ marginTop: '30px', backgroundColor: '#00aaad', width: '302px', height: '35px', paddingTop: '5px' }}
              disabled={this.state.isValidated ?
                !(this.state.password && this.state.confirmPassword) :
                !(this.state.recoveryPhrase && this.state.userName)}
              onClick={this.state.isValidated ? this.resetPassword : this.ValidateDetails}
              label={this.state.isValidated ? "Submit Password" : "Validate User Details"}
            />
            <div
              style={{ marginTop: '10px', color: '#00aaad', cursor: 'pointer' }}
              onClick={this.handleBackButton}
            >
              Back To Login
            </div>
            {this.state.invalidPhrase &&
            <div style={{ marginTop: '10px', color: 'red' }}>User Name or Recovery Phrase is incorrect</div>
            }
            </div>
          </div>
        </Card>
        <Toast ref={(el) => (this.toast = el)} /> 
     </div>
    );
  }
}

export default withTranslation("translations")(ResetPasswordScreen);

import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FilterDropDown from "../common/formfields/FilterDropDown.jsx";
import { ConfigurationService } from "../../service/ConfigurationService.js";
import { withTranslation } from "react-i18next";
import YAML from "yamljs";
import CasButton from "../common/formfields/CasButton";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import "./ToastDemo.css";

class YAMLConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValues: [
        {
          modulename: "",
          executionname: "",
          threadpoolsize: "",
          suitename: "",
          suitefilename: "",
          testdatafiles: "",
          testdatasheetname: "",
          pattern: "",
          "config-propertyfile": "",
          prioritysheetname: "",
          schedulerconfig: ""
        },
      ],

      selectedApp: undefined,
      appConfigurationList: "",
      appConfigJSON: [],
      isAddConfigurationApp: false,
      isAddConfig: false,
      deleteDialog: false,

    };
  }

  componentDidMount = () => {
    this.getAppConfigList();
  };

  getAppConfigList() {
    let appConfigurationList = [];
    ConfigurationService.getConfigApplicationList().then(
      (res) => {
        res.forEach((item) => {
          appConfigurationList.push({
            label: item.appName,
            value: item.appName,
          });
        });
      },
      (err) => {
        this.toast.show({
          severity: "error",
          summary: "Error",
          detail: err,
          life: 3000,
        });
      }
    );
    this.setState({
      appConfigurationList,
    });
    this.setState({ isAddConfig: true });
  }

  //Application dropdown change
  handleAppChange = (e) => {
    const selectedApp = e;
    this.setState(
      {
        selectedApp,
      },
      () => {
        selectedApp && this.getAppConfigDetails(selectedApp);
      }
    );
    this.setState({ isAddConfigurationApp: false });
    this.setState({ isAddConfig: true });
  };

  //Get Application configuration details
  getAppConfigDetails = (selectedApp) => {
    ConfigurationService.getAppConfigDetails(selectedApp).then((res) => {
      let yamlString = res.appConfigYAML;
      if (yamlString) {
        YAML = require("yamljs");
        let appConfigJSON = YAML.parse(yamlString);
        this.setState({
          appConfigJSON,
        });
      } else {
        this.toast.show({
          severity: "error",
          summary: "Error",
          detail: "No configuration available",
          life: 3000,
        });
      }
    });
  };

  //Validation for Application configuration
  validateApplication(appConfigJSON) {
    try {
      if (
        appConfigJSON.appconfig.appname.trim() == "" ||
        appConfigJSON.appconfig.schedulername.trim() == "" ||
        appConfigJSON.appconfig.screenshotbasepath.trim() == ""
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  //Validation for Modules
  validateAppConfig(appConfigJSON) {
    try {
      let modulearray = appConfigJSON.appconfig.modules;
      const validatefunction = (element) => {
        if (
          element.modulename == "" ||
          element.executionname == "" ||
          element.threadpoolsize == "" ||
          element.suitename == "" ||
          element.suitefilename == "" ||
          element.testdatafiles == "" ||
          element.testdatasheetname == "" ||
          element.pattern == "" ||
          element.prioritysheetname == "" ||
          element.schedulerconfig == ""
        ) {
          return true;
        } else {
          return false;
        }
      };
      var isValid = modulearray.some(validatefunction);
      return isValid;
    } catch (error) {
      return false;
    }
  }

  //Add Application configuration details
  addConfiguration = (appConfigJSON) => {

    if (!this.validateApplication(appConfigJSON) &&
      !this.validateAppConfig(appConfigJSON)
    ) {
      let appName = appConfigJSON.appconfig.appname;
      let yaml = YAML.stringify(appConfigJSON, 4);

      ConfigurationService.saveConfigurationService(appName, yaml).then(

        (res) => {
          if (res && res.errorMessage) {

            this.toast.show({
              severity: "error",
              summary: "Error",
              detail: res.errorMessage,
              life: 3000,
            });
          }

          else {
            this.toast.show({
              severity: "success",
              summary: "Success",
              detail: "Configuration saved Successfully",
              life: 3000,
            });
            this.isAddConfigurationApp = false;
            this.clearApplication();
          }
        },

        (err) => {
          this.toast.show({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000,
          });
        }
      );
    } else {
      this.toast.show({
        severity: "error",
        summary: "Error",
        detail: "All fields are required",
        life: 3000,
      });
    }
  };

  //Update Application details
  updateAppConfig = (appConfigJSON, selectedApp) => {
    if (!this.validateAppConfig(appConfigJSON)) {
      let appName = selectedApp;
      let yaml = YAML.stringify(appConfigJSON, 4);
      let updatedAppName = appConfigJSON && appConfigJSON.appconfig && appConfigJSON.appconfig.appname
      ConfigurationService.updateAppConfig(appName, updatedAppName, yaml).then(
        (res) => {
          this.toast && this.toast.show({
            severity: "success",
            summary: "Success",
            detail: "Update Successfully",
            life: 3000,
          });
          this.clearApplication();
        },
        (err) => {
          this.toast.show({
            severity: "error",
            summary: "Error",
            detail: "error display",
            life: 3000,
          });
        }
      );
    } else {
      this.toast.show({
        severity: "error",
        summary: "Error",
        detail: "All fields are required",
        life: 3000,
      });
    }
  };
  //End Update Application details

  //Add Modules
  addModuleFields() {
    const { appConfigJSON } = this.state;
    appConfigJSON.appconfig.modules = [
      ...appConfigJSON.appconfig.modules,
      {
        modulename: "",
        executionname: "",
        threadpoolsize: "",
        suitename: "",
        suitefilename: "",
        testdatafiles: "",
        testdatasheetname: "",
        pattern: "",
        "config-propertyfile": "",
        prioritysheetname: "",
        schedulerconfig: ""
      },
    ];
    this.setState({ appConfigJSON });
  }

  //Add Application configuration
  addApplication() {
    const appConfigJSONNew = {
      appconfig: {
        appname: "",
        schedulername: "",
        screenshotbasepath: "",
        isscreenshotsaveindb: true,
        enablemailservice: false,
        enablecrontabscheduler: false,
        enablecrontabretry: false,
        modules: [
          {
            modulename: "",
            executionname: "",
            threadpoolsize: "",
            suitename: "",
            suitefilename: "",
            testdatafiles: "",
            testdatasheetname: "",
            pattern: "",
            "config-propertyfile": "",
            prioritysheetname: "",
            schedulerconfig: ""
          },
        ],
      },
    };
    this.setState({ appConfigJSON: appConfigJSONNew });
    this.setState({ isAddConfigurationApp: true });
    this.setState({
      selectedApp: undefined,
    });
  }



  //Delete Application Configuration
  deleteApplication(selectedApp) {

    this.setState({ deleteDialog: true });
  }

  hideDeleteDialog = () => {
    this.setState({
      deleteDialog: false
    })
  }
  deleteAction = () => {

    ConfigurationService.deleteAppConfig(this.state.selectedApp)
      .then((result) => {
        this.toast.show({
          severity: "success",
          summary: "Success",
          detail: "Delete Successfully",
          life: 3000,
        });
        this.clearApplication();
      })
      .catch((error) => {
      });
  }


  addTextBox() {
    let a = [1, 2, 3, 4, 5, "", "", ""];
    return a.map((element, indexex) => {
      return (
        <div>
          <input type="textbox" key={indexex} name="" />
        </div>
      );
    });
  }

  //Remove Modules
  removeModuleFields(i) {
    // let formValues = this.state.appConfigJSON.appconfig.modules;
    const { appConfigJSON } = this.state;
    appConfigJSON.appconfig.modules.splice(i, 1);
    this.setState({ appConfigJSON });
    this.toast.show({
      severity: "success",
      summary: "Success",
      detail: "One Module Removed Successfully",
      life: 3000,
    });
  }

  clearApplication() {
    this.setState({
      selectedApp: undefined,
      appConfigurationList: "",
      appConfigJSON: [],
      isAddConfigurationApp: false,
      deleteDialog: false
    });
    this.getAppConfigList();
  }

  handleConfigChange = (key, value) => {
    const { appConfigJSON } = this.state;
    appConfigJSON.appconfig[key] = value;
    this.setState({
      appConfigJSON,
    });
  };

  handleModuleChange = (key, idx, value) => {
    const { appConfigJSON } = this.state;
    appConfigJSON.appconfig.modules[idx][key] = value;
    this.setState({
      appConfigJSON,
    });
  };

  handleModuleChangetoint = (key, idx, value) => {
    const { appConfigJSON } = this.state;
    appConfigJSON.appconfig.modules[idx][key] = parseInt(value);
    this.setState({
      appConfigJSON,
    });
  };

  handleModuleChangesplit = (key, idx, value) => {
    const { appConfigJSON } = this.state;
    appConfigJSON.appconfig.modules[idx][key] = value.split(",");
    this.setState({
      appConfigJSON,
    });
  };



  render() {
    const { appConfigJSON } = this.state;
    const { t } = this.props;
    let deleteLabel = t("delete", { returnObjects: true });
    const dropDownCSS = {
      outline: "0",
      border: "0",
      borderRadius: "3px",
      border: "2px solid #007AD9",
  }

    const deleteDialogFooter = (
      <React.Fragment>
        <CasButton
          label={deleteLabel.no}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideDeleteDialog}
        />
        <CasButton
          label={deleteLabel.yes}
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.deleteAction}
        />
      </React.Fragment>
    );

    return (
      <div className="p-grid p-fluid input-demo yaml-configuration-page">
        <div className="p-col-12 ">
          <div className="card">
            <h3>Application</h3>
            {/* <div className="p-col-2 float-right">
              <span>
                <Button label="Add Textbox" icon="pi pi-plus" onClick={() => this.addTextbox()} />
              </span>
            </div> */}

            <div className="p-grid p-formgrid select-application-container">
              <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                {this.state.appConfigurationList && (
                  <FilterDropDown
                    label="Application"
                    placeholder="Select the Application"
                    options={this.state.appConfigurationList}
                    isRequired={true}
                    id="selectedApp"
                    value={this.state.selectedApp}
                    onChange={(e) => this.handleAppChange(e)}
                    testIdentifier="selectedPoints"
                    info=""
                    style={dropDownCSS}
                    enabled={true}
                  />
                )}
              </div>
              <div className="add-outerdiv p-px-2">
                {this.state.isAddConfig ? (
                  <div className="add-config">
                    <span className="p-buttonset">
                      <Button
                        label="Add Configuration"
                        icon="pi pi-plus"
                        onClick={() => this.addApplication()}
                      />
                    </span>
                  </div>
                ) : (
                    " "
                  )}
                {this.state.selectedApp && (
                  <div>
                    <Button
                      label="Delete Configuration"
                      icon="pi pi-minus"
                      onClick={() =>
                        this.deleteApplication(this.state.selectedApp)
                      }
                    />
                  </div>
                )}
              </div>

              <Toast ref={(el) => (this.toast = el)} />
            </div>
          </div>

          {(this.state.selectedApp || this.state.isAddConfigurationApp) && (
            <>
            <div className="card ">
              <h3>Application Configuration</h3>

              <div className="p-grid p-formgrid ">
                <Dialog
                  className="cas-dialog"
                  visible={this.state.deleteDialog}
                  style={{ width: "30rem" }}
                  header={deleteLabel.header}
                  modal
                  footer={deleteDialogFooter}
                  onHide={this.hideDeleteDialog}
                >
                  <div className="confirmation-content">
                    <i
                      className="pi pi-exclamation-triangle p-mr-3"
                      style={{ fontSize: "2rem" }}
                    />
                    <span>{deleteLabel.confirmMsg}</span>
                  </div>
                </Dialog>
                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                  <label htmlFor="username1" className="p-d-block">
                    Application Name
                    </label>
                  <InputText
                    type="text"
                    maxLength="50"
                    placeholder="eg: CAS_DB_API"
                    value={appConfigJSON ?.appconfig ?.appname}
                    onChange={(e) =>
                      this.handleConfigChange("appname", e.target.value)
                    }
                  ></InputText>
                </div>
                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                  <label htmlFor="username1" className="p-d-block">
                    Scheduler Name
                    </label>
                  <InputText
                    type="text"
                    maxLength="50"
                    placeholder="eg: CAS_DB_API_TEST"
                    value={appConfigJSON ?.appconfig ?.schedulername}
                    onChange={(e) =>
                      this.handleConfigChange("schedulername", e.target.value)
                    }
                  ></InputText>
                </div>

                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                  <label htmlFor="username1" className="p-d-block">
                    Screenshot basepath
                    </label>
                  <InputText
                    type="text"
                    placeholder="eg: D:\\evidence"
                    value={appConfigJSON ?.appconfig ?.screenshotbasepath}
                    onChange={(e) =>
                      this.handleConfigChange(
                        "screenshotbasepath",
                        e.target.value
                      )
                    }
                  ></InputText>
                </div>

                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-3 checkbox-outer">
                  <input
                    id="isScreenshotSaveInDB"
                    type="checkbox"
                    checked={
                      appConfigJSON ?.appconfig ?.isscreenshotsaveindb == true
                        ? true
                        : false
                      }
                    onChange={(e) =>
                      this.handleConfigChange(
                        "isscreenshotsaveindb",
                        e.target.checked
                      )
                    }
                    placeholder="Is Screenshot Save in db"
                  />
                  <label htmlFor="isScreenshotSaveInDB" className="p-d-block">
                    Is Screenshot Save in DB
                    </label>
                </div>

                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-3 checkbox-outer">
                  <input
                    id="enableMailService"
                    type="checkbox"
                    checked={
                      appConfigJSON ?.appconfig ?.enablemailservice == true
                        ? true
                        : false
                      }
                    onChange={(e) =>
                      this.handleConfigChange(
                        "enablemailservice",
                        e.target.checked
                      )
                    }
                    placeholder="Enable Mail Service"
                  />
                  <label htmlFor="enableMailService" className="p-d-block">
                    Enable Mail Service
                    </label>
                </div>

                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-3 checkbox-outer">
                  <input
                    id="enableCronTabScheduler"
                    type="checkbox"
                    onChange={(e) =>
                      this.handleConfigChange(
                        "enablecrontabscheduler",
                        e.target.checked
                      )
                    }
                    placeholder="Enable Cron Tab Scheduler"
                    checked={
                      appConfigJSON ?.appconfig ?.enablecrontabscheduler == true
                        ? true
                        : false
                      }
                  />
                  <label
                    htmlFor="enableCronTabScheduler"
                    className="p-d-block"
                  >
                    Enable Cron Tab Scheduler
                    </label>
                </div>

                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-3 checkbox-outer">
                  <input
                    id="enableCronTabRetry"
                    type="checkbox"
                    checked={
                      appConfigJSON ?.appconfig ?.enablecrontabretry == true
                        ? true
                        : false
                      }
                    onChange={(e) =>
                      this.handleConfigChange(
                        "enablecrontabretry",
                        e.target.checked
                      )
                    }
                    placeholder="Enable Cron Tab Retry"
                  />
                  <label htmlFor="enableCronTabRetry" className="p-d-block">
                    Enable Cron Tab Retry
                    </label>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="p-grid p-formgrid ">
                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                  <h3>Module Configuration </h3>
                </div>
              </div>
              {appConfigJSON &&
                appConfigJSON.appconfig &&
                appConfigJSON.appconfig.modules &&
                appConfigJSON.appconfig.modules.length != 0 &&
                appConfigJSON.appconfig.modules.map((module, idx) => {
                  return (
                    <div key={idx}>
                      <div className="p-grid p-formgrid p-fluid p-mb-lg-4 p-mt-lg-2">
                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor={"modulename" + idx}
                            className="p-d-block"
                          >
                            Module Name
                            </label>
                          <InputText
                            type="text"
                            maxLength="50"
                            placeholder="eg: M1"
                            id={"modulename" + idx}
                            value={module.modulename}
                            onChange={(e) =>
                              this.handleModuleChange(
                                "modulename",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>
                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor={"executionname" + idx}
                            className="p-d-block"
                          >
                            Execution Name
                            </label>
                          <InputText
                            type="text"
                            maxLength="50"
                            placeholder="eg: ANA_API_RUN_M1"
                            id={"executionname" + idx}
                            value={module.executionname}
                            onChange={(e) =>
                              this.handleModuleChange(
                                "executionname",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>
                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor={"threadpoolsize" + idx}
                            className="p-d-block"
                          >
                            Threadpool Size
                            </label>
                          <InputText
                            type="number"
                            min="0"
                            placeholder="eg: 1"
                            id={"threadpoolsize" + idx}
                            value={module.threadpoolsize}
                            onChange={(e) =>
                              this.handleModuleChangetoint(
                                "threadpoolsize",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>
                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor={"suitename" + idx}
                            className="p-d-block"
                          >
                            Suite Name
                            </label>
                          <InputText
                            type="text"
                            maxLength="50"
                            placeholder="eg: CASChildSuite"
                            id={"suitename" + idx}
                            value={module.suitename}
                            onChange={(e) =>
                              this.handleModuleChange(
                                "suitename",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>
                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor={"suitefilename" + idx}
                            className="p-d-block"
                          >
                            Suite File Name
                            </label>
                          <InputText
                            type="text"
                            placeholder="eg: MasterSuite.xml"
                            id={"suitefilename" + idx}
                            value={module.suitefilename}
                            onChange={(e) =>
                              this.handleModuleChange(
                                "suitefilename",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>

                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor={"schedulerconfig" + idx}
                            className="p-d-block"
                          >
                            Scheduler Config
                            </label>
                          <InputText
                            type="text"
                            placeholder="eg: testScheduler.yml"
                            id={"schedulerconfig" + idx}
                            value={module.schedulerconfig}
                            onChange={(e) =>
                              this.handleModuleChange(
                                "schedulerconfig",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>
                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label htmlFor="username1" className="p-d-block">
                            Priority Sheet Name
                            </label>
                          <InputText
                            type="text"
                            placeholder="eg: ParentController"
                            value={module.prioritysheetname}
                            onChange={(e) =>
                              this.handleModuleChange(
                                "prioritysheetname",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>

                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor="TestDataFiles"
                            className="p-d-block"
                          >
                            Test Data Files
                            </label>
                          <InputText
                            type="text"
                            placeholder="eg: CAS_FT_RTF_WS_MQ_BATCH_V0.1.xlsx"
                            value={module.testdatafiles}
                            onChange={(e) =>
                              this.handleModuleChangesplit(
                                "testdatafiles",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>

                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor="TestDataSheetName"
                            className="p-d-block"
                          >
                            Test Data Sheet Name
                            </label>
                          <InputText
                            type="text"
                            placeholder="eg: WS_Request,WS_Response"
                            value={module.testdatasheetname}
                            onChange={(e) =>
                              this.handleModuleChangesplit(
                                "testdatasheetname",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>

                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label
                            htmlFor="ConfigPropertyFile"
                            className="p-d-block"
                          >
                            Config Property File
                            </label>
                          <InputText
                            type="text"
                            placeholder="eg: ElementRepository_ANA_API.properties"
                            value={module["config-propertyfile"]}
                            onChange={(e) =>
                              this.handleModuleChange(
                                "config-propertyfile",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>

                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2">
                          <label htmlFor="Pattern" className="p-d-block">
                            Pattern
                            </label>
                          <InputText
                            type="text"
                            placeholder="eg: TC001,TC002,TC003"
                            value={module.pattern}
                            onChange={(e) =>
                              this.handleModuleChangesplit(
                                "pattern",
                                idx,
                                e.target.value
                              )
                            }
                          ></InputText>
                        </div>
                        <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-2 p-mt-lg-2 p-d-flex p-jc-end p-ai-end">
                          <Button
                            style={{ width: "50%" }}
                            label="Remove"
                            icon="pi pi-minus"
                            onClick={() => this.removeModuleFields(idx)}
                          />
                        </div>
                      </div>

                      <div style={{ marginTop: "10px" }}>
                        <hr className="solid p-my-3" />
                      </div>
                    </div>
                  );
                })}

              <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-mt-lg-4 p-pl-0">
                <span className="p-buttonset">
                  <Button
                    label="Add Module"
                    icon="pi pi-plus"
                    onClick={() => this.addModuleFields()}
                  />
                </span>
              </div>
            </div>

              {this.state.isAddConfigurationApp ? (
                <div className="p-col-12  p-mb-2 p-lg-2 p-mb-lg-2 btn-right">
            <Button
              label="Save Configuration "
              onClick={() =>
                this.addConfiguration(this.state.appConfigJSON)
              }
              className="p-button"
            />
          </div>
          ) : (
                this.state.formValues &&
                this.state.formValues.length != 0 && (
                  <div className="card">
            <div className="p-grid p-formgrid p-fluid">
              <div className="p-col-12 p-mb-12 p-lg-12 p-mb-lg-12">
                <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-pl-0 p-col-2 p-lg-2 float-right p-pr-0">
                  <Button
                    label="Update "
                    onClick={() =>
                      this.updateAppConfig(
                        this.state.appConfigJSON,
                        this.state.selectedApp
                      )
                    }
                    className="p-button"
                  />
                </div>
              </div>
            </div>
          </div>
          )
              )}
            </>
        )}
        </div>
      </div >
    );
  }
}
export default withTranslation("translations")(YAMLConfiguration);

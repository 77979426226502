import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactPaginate from 'react-paginate';
import axios from 'axios';

class PatternList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
            endIndex: 2,
             offset: 0,
             data: [],
             perPage: 3,
             currentPage: 0
        }
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });
    };
  
    receivedData() {
        const data = this.props.data;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
       
        const postData = slice.map(patternItem => <React.Fragment>
                        <div style={{marginTop: "20px"}}>
                            <div style={{padding: "5px"}}>
                            <div style={{border: "solid 1px lightgrey", width: "170px", padding: "15px" }}>
                                <p style={{backgroundColor: "#e0ebff"}}> Pattern Name :<b> {patternItem.patternName}</b></p>
                                <div style={{marginTop: "6px", marginBottom: "6px", display: "flex" }}><div className="icon icon-warning" style={{marginRight: "8px"}}></div> <label>Skipped {patternItem.skipCount} ({patternItem.skipPercentage }%)</label> </div>
                                <hr style={{ borderTop: "2px dotted #bbb" }} />
                                <div style={{marginTop: "6px", marginBottom: "6px", display: "flex" }}><div className="icon icon-alert" style={{marginRight: "8px"}}></div> <label>Failed {patternItem.failCount} ({patternItem.failPercentage }%)</label> </div>
                                <hr style={{ borderTop: "2px dotted #bbb" }} />
                                <div style={{marginTop: "6px", marginBottom: "6px", display: "flex" }}><div className="icon icon-success" style={{marginRight: "8px"}}></div> <label>Passed {patternItem.passCount} ({patternItem.passPercentage }%)</label></div>
                                <hr style={{ borderTop: "2px dotted #bbb" }} />
                            </div>
                            </div>
                            </div> </React.Fragment>
                    )
                     this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage), postData
                })
     }
    componentDidMount() {
        this.receivedData()
    }
    componentDidUpdate = (prevsprops, prevstate) => {
        if(prevsprops.data != this.props.data){
            this.receivedData()
        }
    }
    render() {
        return (
            
            <div>
                 <div style={{display: "flex"}}>
                {this.state.postData}
                </div>
             <div style={{marginInline: "auto", width: "22%", marginTop: "-20px"}}>   
                <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={". . ."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                    </div>
            </div>
            
        );
    }
}

export default withTranslation("translations")(PatternList);
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';

class SchedulerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
            endIndex: 2,
            offset: 0,
            data: [],
            perPage: 2,
            currentPage: 0,
            postData: [],
            pageCount: 0,
            data: [],
            toggleListGrid: true,
            schedularDetails: [],
            schedularDetailsToDisplay: []
        }
    }

    componentDidMount = () => { }

    componentDidUpdate(prevprops, prevstate) {
        const { data } = this.props
        if (prevprops.data != data) {
            this.setState({
                toggleListGrid: true,
                schedularDetails: data,
                schedularDetailsToDisplay: data
            }, () => {
                this.handleSort("Last Executed");
                this.setState({
                    pageCount: Math.ceil(this.state.schedularDetailsToDisplay.length / this.state.perPage)
                })

            })
        }
        if (prevstate.schedularDetailsToDisplay != this.state.schedularDetailsToDisplay) {
            this.setState({
                pageCount: Math.ceil(this.state.schedularDetailsToDisplay.length / this.state.perPage)
            })
        }
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            // this.receivedData(this.state.schedularDetailsToDisplay)
        });
    };

    // receivedData = (schedularDetails) => {

    //     const slice = schedularDetails && schedularDetails.length >= 2 ?
    //         schedularDetails.slice(this.state.offset, this.state.offset + this.state.perPage) : schedularDetails;
    //     if (slice && slice.length != 0) {
    //         const postData = slice.map((schedulerItem, idx) => <React.Fragment>
    //             <div>
    //                 <div className={this.props.toggleListGrid ? "shed-displaylist-main-div" : "shed-displaygrid-main-div"} >
    //                     <h3 style={{ marginTop: "14px" }}>Scheduler Id :{schedulerItem.schedularId}
    //                         <span>
    //                             <button
    //                                 className="pi pi-chevron-right onhover"
    //                                 style={{ float: "right", marginTop: "33px", marginRight: "4px", border: "none", padding: "6px" }}
    //                                 onClick={() => this.props.getSchedulerItems(slice, idx)}
    //                                 type="button"
    //                             >
    //                             </button>
    //                         </span>
    //                     </h3>
    //                     <p style={{ marginTop: "10px" }}><b style={{ fontSize: "13px" }}>Scheduler Name : </b>{schedulerItem.schedulerName}</p>
    //                     <span className="detail"><b style={{ fontSize: "13px", marginRight: "110px" }}>{"Start:"}</b>{schedulerItem.starttime} </span>
    //                     <span className="detail"><b style={{ fontSize: "13px", marginRight: "115px" }}>{"End:"}</b>{schedulerItem.endtime} </span>
    //                     <span className="detail"><b style={{ fontSize: "13px", marginRight: "89px" }}>{"Duration:"}</b>{schedulerItem.totalDuration}</span>

    //                 </div>
    //             </div>
    //         </React.Fragment>
    //         )
    //         this.setState({
    //             pageCount: Math.ceil(schedularDetails.length / this.state.perPage), postData: postData
    //         })
    //     }
    // }

    handleSort = (value) => {
        const { schedularDetails } = this.state
        let sortSchedulerList = schedularDetails
        if (value === "Last Executed") {
            sortSchedulerList.sort((nextElement, element) => {
                if (nextElement.schedularId > element.schedularId) {
                    return -1;
                }
                if (nextElement.schedularId < element.schedularId) {
                    return 1;
                }
                return 0;
            }
            )
        } else if (value === "Scheduler Id") {
            sortSchedulerList.sort((nextElement, element) => {
                if (nextElement.schedularId < element.schedularId) {
                    return -1;
                }
                if (nextElement.schedularId > element.schedularId) {
                    return 1;
                }
                return 0;
            }
            )
        } else if (value === "Scheduler name") {
            sortSchedulerList.sort((nextElement, element) => {
                if (nextElement.schedulerName < element.schedulerName) {
                    return -1;
                }
                if (nextElement.schedulerName > element.schedulerName) {
                    return 1;
                }
                return 0;
            }
            )
        } else if (value === "Start Time") {
            sortSchedulerList.sort((nextElement, element) => {
                if (nextElement.starttime > element.starttime) {
                    return -1;
                }
                if (nextElement.starttime < element.starttime) {
                    return 1;
                }
                return 0;
            }
            )
        } else if (value === "End Time") {
            sortSchedulerList.sort((nextElement, element) => {
                if (nextElement.endtime > element.endtime) {
                    return -1;
                }
                if (nextElement.endtime < element.endtime) {
                    return 1;
                }
                return 0;
            }
            )
        } else if (value === "Duration") {
            sortSchedulerList.sort((nextElement, element) => {
                if (nextElement.totalDuration < element.totalDuration) {
                    return -1;
                }
                if (nextElement.totalDuration > element.totalDuration) {
                    return 1;
                }
                return 0;
            }
            )
        }
        this.setState({
            schedularDetailsToDisplay: sortSchedulerList
        })
    }

    handleSearchByName = (value) => {
        const { schedularDetails } = this.state
        let schedularDetailsToDisplay
        if (value.length > 0 || value != "") {
            schedularDetailsToDisplay = schedularDetails.filter(e => e.schedulerName.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
        } else {
            schedularDetailsToDisplay = schedularDetails
        }
        this.setState({
            schedularDetailsToDisplay
        })
    }

    render() {
        const { schedularDetailsToDisplay } = this.state
        const slicedSchedularDetails = schedularDetailsToDisplay &&
            schedularDetailsToDisplay.length >= 2 ?
            schedularDetailsToDisplay.slice(this.state.offset, this.state.offset + this.state.perPage) :
            schedularDetailsToDisplay;

        return (

            <div>
                <div style={{ marginTop: "10px", border: "1px solid lightgray" }}>
                    <div className="card summary" >
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div>
                                <select onChange={(e) => (this.handleSort(e.target.value))}
                                    style={{ borderColor: "darkgray", borderRadius: "4px", width: "150%", height: "100%" }}>
                                    <option value="Last Executed" >Last Executed </option>
                                    <option value="Scheduler Id">Scheduler Id</option>
                                    <option value="Scheduler name">Scheduler Name</option>
                                    <option value="Start Time" >Start Time </option>
                                    <option value="End Time">End Time </option>
                                    <option value="Duration">Duration </option>
                                </select>
                            </div>
                            <div>
                                <InputText
                                    style={{ width: "105%", marginLeft: "26px", height: "95%" }}
                                    id="schedulerName" type="text"
                                    placeholder="Search by Scheduler Name"
                                    onChange={(e) => (this.handleSearchByName(e.target.value))}
                                />
                            </div>
                            <div className="scheddisplay">
                                <span className="pi pi-list schedspan onhover"
                                    onClick={() => this.setState({ toggleListGrid: true })} >
                                </span>
                                <span className="pi pi-th-large schedspan onhover"
                                    onClick={() => this.setState({ toggleListGrid: false })}>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={!this.state.toggleListGrid ? "gridStyle" : ""}>
                        {
                            slicedSchedularDetails.map((schedulerItem, idx) => {
                                return <div>
                                    <div className={this.props.toggleListGrid ? "shed-displaylist-main-div" : "shed-displaygrid-main-div"} >
                                        <h3 style={{ marginTop: "14px" }}>Scheduler Id :{schedulerItem.schedularId}
                                            <span>
                                                <button
                                                    className="pi pi-chevron-right onhover"
                                                    style={{ float: "right", marginTop: "33px", marginRight: "4px", border: "none", padding: "6px" }}
                                                    onClick={() => this.props.getSchedulerItems(slicedSchedularDetails, idx)}
                                                    type="button"
                                                >
                                                </button>
                                            </span>
                                        </h3>
                                        <p style={{ marginTop: "10px" }}><b style={{ fontSize: "13px" }}>Scheduler Name : </b><i>{schedulerItem.schedulerName}</i></p>
                                        <span className="detail"><b style={{ fontSize: "13px", marginRight: "110px" }}>{"Start:"}</b>{schedulerItem.starttime} </span>
                                        <span className="detail"><b style={{ fontSize: "13px", marginRight: "115px" }}>{"End:"}</b>{schedulerItem.endtime} </span>
                                        <span className="detail"><b style={{ fontSize: "13px", marginRight: "89px" }}>{"Duration:"}</b>{schedulerItem.totalDuration}</span>

                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div style={{ marginInline: "auto", width: "22%", marginTop: "-20px" }}>
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={". .."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </div>
            </div>
        );
    }
}
export default withTranslation("translations")(SchedulerDetails);
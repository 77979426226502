import React from "react";
import { withTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldValues: {},
      isInvalidEmail: false,
      isInvalidPassword: false,
      isConfirmPasswordEnabled: false
    };
  }

  componentDidMount = () => {
    const { userDetails, isEdit } = this.props;
    if (isEdit) {
      const modifiedData = {...userDetails};
      modifiedData.userRole = userDetails.userRole.split(', ');
      this.setState({ fieldValues: modifiedData });
    }
  };

  getLabel = item => {
    switch (item) {
      case "userName":
        return "Username";
      case "displayName":
        return "Display Name";
      case "userRole":
        return "User Role";
      case "emailAddress":
        return "Email Address";
      case "initialPassword":
        return "Initial Password";
      case "confirmPassword":
        return "Confirm Password";
      default:
        return "Password recovery word phrase";
    }
  };

  getErrorLabel = (item) => {
    switch (item) {
      case "emailAddress":
        return "Please enter a valid email address";
      default:
        return "Password doesn't match";
    }
  };
  
  handleValueChange = (item, value) => {
    const modifiedValues = { ...this.state.fieldValues };
    const emailRegex = /\S+@\S+\.\S+/;
    if (item === "initialPassword") {
      if (value !== "") {
        this.setState({ isConfirmPasswordEnabled: true });
      } else {
        this.setState({ isConfirmPasswordEnabled: false });
      }
    }
    if (item === "userRole" && value.length === 0) {
      delete modifiedValues.userRole;
    } else if (value === '') {
       delete modifiedValues[item];
    } else {
      if (item === "emailAddress") {
        if (!emailRegex.test(value)) {
          this.setState({ isInvalidEmail: true });
        } else {
          this.setState({ isInvalidEmail: false });
        }
      } 
      if (item === "confirmPassword") {
        if (value !== modifiedValues.initialPassword) {
          this.setState({ isInvalidPassword: true });
        } else {
          this.setState({ isInvalidPassword: false });
        }
      }
      modifiedValues[item] = value;
    }
    this.setState({ fieldValues: modifiedValues });
  };

  handleButtonAction = () => {
    const modifiedData = { ...this.state.fieldValues };
    if (!this.props.isEdit) {
      modifiedData.userPassword = modifiedData.confirmPassword;
      delete modifiedData.confirmPassword;
      delete modifiedData.initialPassword;
    }
    let modifiedUserRole = [...modifiedData.userRole];
    modifiedData.userRole = modifiedUserRole.join(", ");
    this.props.handleSaveUser(modifiedData, this.props.isEdit);
  };

  render() {
    const fieldList = [
      "userName",
      "displayName",
      "userRole",
      "emailAddress"
    ];

    if (!this.props.isEdit) {
      fieldList.splice(3, 0, "initialPassword", "confirmPassword", "passwordRecoveryPhrase");
    };

    const userRoleList = [
      {label: 'ADMIN', value: 'ADMIN'},
      {label: 'USER', value: 'USER'}     
    ];

    return (
      <div className="card">
        <div style={{ fontWeight: 700 }}>Profile</div>
        <div style={{ marginLeft: "5%" }}>
          {fieldList.map(item => (
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid #9e9e9e",
                padding: "10px"
              }}
            >
              <div style={{ width: "30%", marginTop: "13px" }}>
                {this.getLabel(item)}
              </div>
              <div  style={{ width: "65%" }}>
                {item === "userRole" ? (
                <MultiSelect 
                  style={{ height: "44px", width: "100%", paddingTop: "5px" }}
                  value={this.state.fieldValues.userRole} 
                  options={userRoleList} 
                  placeholder="Select User Role..." 
                  onChange={(e) => this.handleValueChange(item, e.target.value)}
                />
                ) : (
                <InputText
                  id={item}
                  style={{ height: "44px", width: "100%" }}
                  value={this.state.fieldValues[item] && this.state.fieldValues[item]} 
                  type={(item === "initialPassword" || item === "confirmPassword") ? "password" : "text"}
                  placeholder={`Enter ${this.getLabel(item)}...`}
                  disabled={item === "confirmPassword" && !this.state.isConfirmPasswordEnabled || (item === "userName" && this.props.isEdit)} 
                  onBlur={(e) => (!this.state.fieldValues[item] && ((!this.props.isEdit && item === "emailAddress") ||
                    item === "confirmPassword")) &&
                    this.handleValueChange(item, e.target.value)
                  }
                  onChange={(e) => (this.state.fieldValues[item] ||
                    (this.props.isEdit || item !== "emailAddress") &&
                    item !== "confirmPassword") &&
                    this.handleValueChange(item, e.target.value)
                  }
                />
                )}
                {((item === "emailAddress" && this.state.isInvalidEmail) ||
                (item === "confirmPassword" && this.state.isInvalidPassword)) &&
                  <div style={{ color:"red", marginTop: "6px" }}>
                    {this.getErrorLabel(item)}
                  </div>
                }    
              </div>
            </div>
          ))}
        </div>
        <Button
          className="p-button"
          style={{ marginTop: "4%", marginLeft: "5%" }}
          label={this.props.isEdit ? "Update" : "Submit"}
          disabled={this.state.isInvalidPassword ||
            this.state.isInvalidEmail ||
            Object.keys(this.state.fieldValues).length !== 7}
          onClick={() => this.handleButtonAction()}
        />
      </div>
    );
  }
}
export default withTranslation("translations")(UserDetails);
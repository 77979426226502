import axios from "axios";
import { ErrorConstants } from "../constants/constants";
import { AuthService } from "./AuthService";
import {APIConstants} from "../constants/APIConstants"

export const ConfigurationService = {
  getConfigApplicationList,
  getAppConfigDetails,
  updateAppConfig,
  saveConfigurationService,
  deleteAppConfig,
};

function getConfigApplicationList() {
  AuthService.setupAxiosInterceptor();
  let headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Access-Control-Allow-Credentials", "true");
  return axios
    .get(
      `${APIConstants.FETCH_ALL_CONFIG}?userRole=${AuthService.getRoles()}`
    )
    .then((res) => {
      if (res) {
        return Promise.resolve(res.data);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    });
}

function getAppConfigDetails(selectedApp) {
  AuthService.setupAxiosInterceptor();
  let headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Access-Control-Allow-Credentials", "true");
  return axios
    .post(
      `${APIConstants.FETCH_CONFIG_BY_NAME}?appName=${selectedApp}`,
      headers
    )
    .then((res) => {
      if (res) {
        return Promise.resolve(res.data);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    });
}

function updateAppConfig(appName, updatedAppName, yaml) {
  let yamlString = yaml.toString();
  let params = {
    appName: appName,
    updatedAppName,
    appConfigYAML: yamlString,
  };
  AuthService.setupAxiosInterceptor();
  let headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Access-Control-Allow-Credentials", "true");
  return (
    axios
      .post(
        `${APIConstants.UPDATE_CONFIG}`,
        params
      )
      .then((res) => {
        if (res) {
          return Promise.resolve(res.data);
        } else {
          let error = [ErrorConstants.CAS_GUI_ERR_01];
          return Promise.reject(error);
        }
      })
      .catch((err) => {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      })
  );
}

function deleteAppConfig(appName) {
  let params = {
    appName: appName,
  };

  AuthService.setupAxiosInterceptor();
  let headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Access-Control-Allow-Credentials", "true");
  return axios
    .get(
      `${APIConstants.DELETE_CONFIG}?appName=${appName}`,
      headers
    )
    .then((res) => {
      if (res) {
        return Promise.resolve(res.data);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    });
}

function saveConfigurationService(appName, yaml) {
  let yamlString = yaml.toString();
  let params = {
    appName: appName,
    appConfigYAML: yamlString,
    userRole: AuthService.getRoles()
  };

  AuthService.setupAxiosInterceptor();
  let headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Access-Control-Allow-Credentials", "true");
  return (
    axios
      .post(
          `${APIConstants.ADD_CONFIG}`,
        params
      )
      .then((res) => {
        if (res) {
          return Promise.resolve(res.data);
        } else {
          let error = [ErrorConstants.CAS_GUI_ERR_01];
          return Promise.reject(error);
        }
      })
      .catch((err) => {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      })
  );
}

import React from "react";
import { withTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { css } from "@emotion/react";
import { UserService } from "../../service/UserService";
import UserDetails from "./UserDetails"
import "../configuration/ToastDemo.css";
import CasButton from "../common/formfields/CasButton";

class ManageUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserDialog: false,
      showDeleteDialog: false,
      isEdit: false,
      userData: [],
      currentUserData: {}
    };
  }

  componentDidMount = () => {
    this.fetchUserDetails();
  };

  fetchUserDetails = () => {
    UserService.fetchUserDetails().then((res) => {
      this.setState({ userData: res });
    },
      (err) => {
        this.toast.show({
          severity: "error",
          summary: "Error",
          detail: err,
          life: 3000,
        });
      });
  };
    
  handleSaveUser = (data, isEdit) => {
    const serviceName = isEdit ? 'updateUserDetailService' : 'createUserDetailService'
    UserService[serviceName](data).then(
      (res) => {
      if (res && res.errorMessage) {
        this.toast.show({
          severity: "error",
          summary: "Error",
          detail: res.errorMessage,
          life: 3000,
        });
      } else {
        this.handleRequestClose();
        this.fetchUserDetails();
        this.toast.show({
          severity: "success",
          summary: "Success",
          detail: isEdit ? "Updated User Details Successfully" : "User Added Successfully",
          life: 3000,
        });
      }},
      (err) => {
      this.toast.show({
        severity: "error",
        summary: "Error",
        detail: err,
        life: 3000,
      });
    });
  };

  onUserDelete = () => {
    UserService.deleteUserDetails(this.state.currentUserData.userName)
      .then((result) => {
        this.handleRequestClose();
        this.fetchUserDetails();
        this.toast.show({
          severity: "success",
          summary: "Success",
          detail: "User Deleted Successfully",
          life: 3000,
        });
      })
      .catch((error) => {
      }); 
  } 

  handleRequestClose = () => {
    this.setState({ 
      showUserDialog: false,
      showDeleteDialog: false,
      isEdit: false
    });
  }; 

  getColumnDetails = (columnList) => columnList.map(element => {
    return <Column key={element.field} field={element.field} header={element.header} />
  });
  
  openDeleteDialog = (userDetails) => {
    this.setState({ showDeleteDialog: true, currentUserData: userDetails });
  };

  openUserDialog = (userDetails) => {
    this.setState({ showUserDialog: true, isEdit: true, currentUserData: userDetails });
  };

  getIcons = (userDetails) => {
    if (userDetails.userName !== localStorage.getItem('userName')) {
      return <div style={{ display: "flex", justifyContent: "space-around" }}>
        <a className="pi pi-pencil" style={{ color: "green" }} onClick={() => this.openUserDialog(userDetails)}></a>
        <a className="pi pi-trash" style={{ color: "red" }} onClick={() => this.openDeleteDialog(userDetails)}></a>
      </div>
    }
  };

  render() {
    const { userData } = this.state;
    const columnList = [
      {field: 'userName', header: 'User Name'},
      {field: 'displayName', header: 'Display Name'},
      {field: 'userRole', header: 'User Role'},
      {field: 'emailAddress', header: 'Email Address'}
    ];

    const deleteDialogFooter = (
      <React.Fragment>
        <CasButton
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.handleRequestClose}
        />
        <CasButton
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.onUserDelete}
        />
      </React.Fragment>
    );

    return (
      <div>
        <div className="card" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{ margin: '3px' }}>Manage User </h3>
          <Button
            className="p-button"
            label="Add User"
            onClick={() => this.setState({ showUserDialog: true })} 
          />
        </div>
        {userData.length === 0 ?
        <div style={{ display: "flex", justifyContent: "center" }}>
          No data to display
        </div> :
        <DataTable
          value={userData}
          rows={10}
          editMode="cell"
          rowHover={true}
          paginator={true}
        >
          {this.getColumnDetails(columnList)}
          <Column 
            headerStyle={{ width: '7rem' }}
            bodyStyle={{ textAlign: 'center' }}
            body={(data) => this.getIcons(data)}
          />
        </DataTable>}
        {this.state.showUserDialog &&
          <Dialog
            className="cas-dialog"
            visible={this.state.showUserDialog}
            style={{ width: "50rem" }}
            header="User Details"
            modal={true}
            onHide={() => this.handleRequestClose()}
          >
            <UserDetails
              handleSaveUser={(data, isEdit) => this.handleSaveUser(data, isEdit)}
              userDetails={this.state.currentUserData}
              isEdit={this.state.isEdit}
            />
          </Dialog> 
        }
        {this.state.showDeleteDialog &&
          <Dialog
            className="cas-dialog"
            visible={this.state.showDeleteDialog}
            style={{ width: "30rem" }}
            header="Delete Confirm"
            modal={true}
            footer={deleteDialogFooter}
            onHide={() => this.handleRequestClose()}
          >
            <div style={{ display: "flex" }} className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              <span style= {{ marginTop: "5px" }}>{`Are you sure you want to delete user - ${this.state.currentUserData.userName}`}</span>
            </div>
          </Dialog> 
        }
        <Toast ref={(el) => (this.toast = el)} />       
      </div>
    );
  }
}
export default withTranslation("translations")(ManageUser);
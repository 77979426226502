import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import HomeScreen from "./components/home/HomeScreen";
import { HashRouter } from 'react-router-dom'
// importing cas-gui-styles and prime react styles
import "./assets/sass/common.scss";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

// translation dependencies
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

// importing font awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlane,
  faPlaneArrival,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";

// adding icons to library
library.add(faPlane, faPlaneArrival, faPlaneDeparture);

class ReportApp extends Component {
  render() {
    return (
      <HashRouter>
        <I18nextProvider i18n={i18n}>
          <HomeScreen />
        </I18nextProvider>
      </HashRouter>
    );
  }
}

export default ReportApp;

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Topbar from "./Topbar";
import { ReportService } from "../../service/ReportService";
import { DashBoardService } from "../../service/DashBoardService";
import SchedulerChart from "./SchedulerChart";
import SchedulerDetails from "./SchedulerDetails";
import PatternList from "./PatternList";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Carousel } from "primereact/carousel";
import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import FailureDialogue from "../common/dialogue/FailureDialog";
import SuccessDialog from "../common/dialogue/SuccessDialog";
import Tooltip from "../common/tooltip/Tooltip";

const initialData = {
  selectedEnvironment: "",
  totalCount: 0,
  totalPassCount: 0,
  totalFailCount: 0,
  totalSkipCount: 0,
};

class CasUserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduler: [],
      failedRow: { verificationResult: [] },
      successRow: { verificationResult: [] },
      apiLoader: false,
      showSearchMessage: "",
      data: initialData,
    };
  }

  componentDidMount = () => {
    this.getAppListAndDashBoardDetails();
  };

  componentDidUpdate = (prevprops, prevstate) => { };

  getAppListAndDashBoardDetails = () => {
    this.setState({ apiLoader: true });
    let enviormentList = [];
    ReportService.getEnviormentDetails().then((res) => {
      res.forEach((item) => {
        enviormentList.push({ value: item.appId, label: item.appName });
      });
    });
    this.setState(
      {
        enviormentList,
      },
      () => {
        this.getDashBoardDetails();
      }
    );
  };

  getDashBoardDetails = () => {
    this.modules = [];
    this.modulePass = [];
    this.moduleFail = [];
    this.moduleSkip = [];
    DashBoardService.getEnviormentDetails()
      .then((appResult) => {
        this.setState({
          environment: appResult.appName,
          appId: appResult.appId,
        });
        this.setDashboardDetails(appResult.appId);
      })
      .catch((err) => {
        this.setState({
          apiLoader: false,
          loading: false,
          showSearchMessage: "*No application record found",
        });
      });
  };

  setDashboardDetails = (appId) => {
    const { data } = this.state;
    data["selectedEnvironment"] = appId;
    this.setState({
      data,
      apiLoader: true,
    });
    DashBoardService.updateDashBoard(appId)
      .then((dashBoardres) => {
        this.setState({
          schedlerItems: dashBoardres,
        });
        this.getSchedulerItems(dashBoardres, 0);
        this.setState({ loading: true, apiLoader: true });
        let param = {
          appId,
          schedulerId: this.state.scheduler.schedularId,
        };
        DashBoardService.getDashBoardResultDetails(param).then((res) => {
          this.setState({
            loading: false,
            apiLoader: false,
            tableData: res[0].resultVo,
          });
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          apiLoader: false,
        });
      });
  };

  getSchedulerItems = (data, index) => {
    const modifiedData = { ...data[index] };
    modifiedData.moduleList[0].showModuleDetails = true;

    this.setState({
      scheduler: modifiedData,
      moduleList: this.modules,
      modulePasscount: this.modulePass,
      moduleFailCount: this.moduleFail,
      moduleSkipcount: this.moduleSkip,
      dashboardDetails: data,
    });
    data[index].moduleList.forEach((item) => {
      this.modules.push(item.moduleName);
      this.modulePass.push(item.totalPassCount);
      this.moduleFail.push(item.totalFailCount);
      this.moduleSkip.push(item.totalSkipCount);
    });
  };

  getPieData = (data) => {
    const pieData = {
      labels: ["Passed", "Failed", "Skipped"],
      datasets: [
        {
          data: [data.totalPassCount, data.totalFailCount, data.totalSkipCount],
          backgroundColor: ["#66BB6A", "rgb(239, 98, 98)", "#FFA726"],
          hoverBackgroundColor: ["#66BB6A", "rgb(239, 98, 98)", "#FFA726"],
        },
      ],
    };
    return pieData;
  };

  handleToggleButton = (index) => {
    const modifiedData = { ...this.state.scheduler };
    modifiedData.moduleList.map((moduleItem, itemIndex) => {
      if (index === itemIndex) {
        moduleItem.showModuleDetails = !moduleItem.showModuleDetails;
      } else {
        moduleItem.showModuleDetails = false;
      }
    });
    this.setState({ scheduler: modifiedData });
  };

  enviormentChange = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState(
      {
        data,
      },
      () => {
        if (value != null) {
          this.setDashboardDetails(value);
        }
      }
    );
  };

  renderColumns(columns) {
    let headerClassName;
    let columList = [];
    columns.map((col, index) => {
      headerClassName = "report-data-table-header ";
      if (col.headerClassName) {
        headerClassName = headerClassName + col.headerClassName;
      }
      columList.push(
        <Column
          key={`key${index}`}
          field={col.field}
          header={col.header}
          sortable={col.sortable}
          className={col.className}
          headerClassName={headerClassName}
          body={col.body}
          editor={col.editor}
        />
      );
      return 0;
    });
    return columList;
  }

  screenShotTemplate = (rowData) => {
    const isZeroScreenshotCondition = rowData.screenShotCount === 0;

    return (
      <React.Fragment>
        <span
          className="screenshotbox screenshotcount"
          onClick={(e) => this.viewScreenShot(rowData)}
        >
          {isZeroScreenshotCondition ? (
            <Tooltip text="No Screenshots Available">
              <b className="table-screenshot-count">
                {rowData.screenShotCount}
              </b>
            </Tooltip>
          ) : (
            <b className="table-screenshot-count">{rowData.screenShotCount}</b>
          )}
        </span>
      </React.Fragment>
    );
  };

  statusTemplate = (rowData) => {
    if (rowData.status == "SUCCESS") {
      return (
        <React.Fragment>
          <Button
            id="successBtn"
            name="successBtn"
            label={rowData.status}
            onClick={(e) => this.handleSuccessCase(rowData)}
            className="p-button-rounded p-button-success p-mr-2"
          />
        </React.Fragment>
      );
    } else if (rowData.status == "FAILED") {
      return (
        <React.Fragment>
          <Button
            id="fail"
            icon="pi pi-info-circle"
            className="p-button-rounded p-button-danger p-mr-2"
            name="failBtn"
            label={rowData.status}
            onClick={(e) => this.handleFailedCase(rowData)}
          />
        </React.Fragment>
      );
    } else if (rowData.status == "SKIPPED") {
      return (
        <React.Fragment>
          <Button
            id="skip"
            className="p-button-rounded p-button-warning p-mr-2"
            name="skipBtn"
            label={rowData.status}
          />
        </React.Fragment>
      );
    } else if (rowData.status == "ERROR") {
      return (
        <React.Fragment>
          <Button
            id="error"
            className="p-button-rounded p-button-danger p-mr-2"
            name="errorBtn"
            label={rowData.status}
          />
        </React.Fragment>
      );
    }
  };

  handleFailedCase = (failedRow) => {
    this.setState({ visible: true, failedRow: failedRow });
  };

  handleSuccessCase = (successRow) => {
    this.setState({ visibleSuccess: true, successRow: successRow });
  };

  /**
   * Function will trigger on clicking the Screenshot Count Column
   * Will Not be triggered incase of No Screenshots
   */
  viewScreenShot = (rowData) => {
    if (rowData.screenShotCount !== 0) {
      this.setState({ loading: true });
      ReportService.getTestScreenShots(rowData.resultId).then((res) => {
        this.setState({ loading: false });
        if (res && res.length > 0) {
          this.screenShotResult = [];
          let count = 1;
          for (let i in res) {
            if (res[i].screenShot != null) {
              let screenShotName;
              if (res[i].screenShotFilePath != null) {
                let filePath = res[i].screenShotFilePath.includes("\\") ? res[i].screenShotFilePath.split("\\") : res[i].screenShotFilePath.split("/");
                screenShotName = filePath[filePath.length - 1];
              }
              this.screenShotResult.push({
                screenshot: "data:image/jpeg;base64," + res[i].screenShot,
                name: screenShotName,
              });
              count++;
            }
          }
          // If there are Screenshots in the response
          if (this.screenShotResult.length > 0) {
            this.setState({ screenShotDlg: true });
          }
        }
      });
    }
  };

  render() {
    const { data } = this.state;
    const { t } = this.props;
    let label = t("dashboardInfo", { returnObjects: true });

    const override = css`
      display: inline;
      margin: 1.4rem;
    `;
    const paginatorLeft = (
      <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
      <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );
    // const screenShotTemplateCaraousel = (result) => {
    //   return (
    //     <div className="screenshotitem">
    //       <h6>{result.name}    </h6>
    //       <img src={result.screenshot} alt="carousel-item" width="680" />
    //     </div>
    //   );
    // };
    const screenShotTemplateCaraousel = (result) => {
      return (
        <div className="product-item">
          <div style={{ textAlign: "center", marginBottom: "1rem" }}>
            <h2 style={{ fontWeight: "700" }} className="p-mb-1">
              Test Case : {result.name}
            </h2>
          </div>
          <div className="product-item-content">
            <div className="p-mb-3">
              <img
                src={result.screenshot}
                alt="carousel-item"
                className="product-image"
              />
            </div>
          </div>
        </div>
      );
    };

    const responsiveOptions = [
      {
        breakpoint: "480px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    let renderColumns = [
      {
        field: "testCaseReportId",
        header: label.table.testCaseId,
        headerClassName: "testCaseId-header",
      },
      {
        field: "testCaseName",
        header: label.table.testCasename,
        sortable: true,
        headerClassName: "testCasename-header",
      },
      {
        field: "moduleName",
        header: label.table.moduleName,
        sortable: true,
        headerClassName: "moduleName-header",
      },
      {
        field: "pattern",
        header: label.table.pattern,
        sortable: true,
        headerClassName: "pattern-header",
      },
      {
        field: "starttime",
        header: label.table.starttime,
        sortable: true,
        headerClassName: "starttime-header",
      },
      {
        field: "endtime",
        header: label.table.endtime,
        sortable: true,
        headerClassName: "endtime-header",
      },
      {
        field: "total_time_taken",
        header: label.table.totaltimetaken,
        sortable: true,
        headerClassName: "totaltimetaken-header",
      },
      {
        field: "status",
        header: label.table.status,
        sortable: true,
        body: this.statusTemplate,
        headerClassName: "status-header",
      },
      {
        field: "screenShotCount",
        header: label.table.screenshot,
        sortable: true,
        headerClassName: "screenshot-header",
        body: this.screenShotTemplate,
      },
    ];
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-3v">
          {this.state.apiLoader && (
            <div className="sweet-loading p-col-8">
              <div
                style={{
                  marginLeft: "40em",
                  marginTop: "0.3rem",
                  marginbottom: "0.3rem",
                }}
              >
                <PropagateLoader
                  color="#00aaad"
                  size={25}
                  loading={this.state.apiLoader}
                  css={override}
                />
              </div>
            </div>
          )}
          {this.state.showSearchMessage && (
            <div style={{ color: "red", display: "flex", marginTop: "10px" }}>
              {this.state.showSearchMessage}
            </div>
          )}
          <Topbar
            environment={this.state.environment}
            options={this.state.enviormentList}
            value={data.selectedEnvironment}
            enviormentChange={(key, value) => this.enviormentChange(key, value)}
          />
        </div>
        <div className="p-col-12 p-lg-3">
          <div className="card summary">
            <span className="title">Total Testcases </span>
            <span className="count blue" style={{ marginTop: "4px" }}>
              {this.state.scheduler.totalCount}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-lg-3">
          <div className="card summary">
            <span className="title">Passed</span>
            <span className="count green" style={{ marginTop: "4px" }}>
              {this.state.scheduler.totalPassCount}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-lg-3">
          <div className="card summary">
            <span className="title">Failed</span>
            <span className="count red" style={{ marginTop: "4px" }}>
              {this.state.scheduler.totalFailCount}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-lg-3">
          <div className="card summary">
            <span className="title">Skipped</span>
            <span className="count yellow" style={{ marginTop: "4px" }}>
              {this.state.scheduler.totalSkipCount}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-lg-6">
          <div className="card  summary">
            <span className="title" style={{ display: "inline" }}>
              {label.cancResHeader}{" "}
            </span>
            <span className="detail" style={{ display: "inline" }}>
              <i>
                {this.state.scheduler.schedulerName}
                <i />
              </i>
            </span>
            <hr style={{ borderTop: "2px dotted #bbb" }} />
            <SchedulerChart
              data={this.getPieData(this.state.scheduler)}
              totalPercent={this.state.scheduler.totalPercentage}
              starttime={this.state.scheduler.starttime}
              endtime={this.state.scheduler.endtime}
              totalDuration={this.state.scheduler.totalDuration}
            />
            <SchedulerDetails
              data={this.state.schedlerItems}
              getSchedulerItems={(item, id) => this.getSchedulerItems(item, id)}
              toggleListGrid={true}
            />
          </div>
        </div>
        <div className="p-col-12 p-lg-6">
          <div className="card summary">
            <span className="title">{label.moduleStatHeader}</span>
            {this.state.scheduler.moduleList &&
              this.state.scheduler.moduleList.map((moduleItem, index) => (
                <div style={{ marginTop: "15px" }}>
                  <div
                    onClick={() => this.handleToggleButton(index)}
                    style={{
                      display: "flex",
                      backgroundColor: "#e0ebff",
                      borderBottom: moduleItem.showModuleDetails
                        ? "solid 1px green"
                        : "none",
                      height: "30px",
                      padding: "6px",
                    }}
                  >
                    <div
                      className={
                        moduleItem.showModuleDetails
                          ? "pi pi-chevron-down"
                          : "pi pi-chevron-right"
                      }
                      style={{ color: "green", marginTop: "2px" }}
                    ></div>
                    <div style={{ marginLeft: "5px" }}>
                      {moduleItem.moduleName}
                    </div>
                  </div>
                  {moduleItem.showModuleDetails && (
                    <div>
                      <SchedulerChart
                        data={this.getPieData(moduleItem)}
                        options={this.lightOptions}
                        totalPercent={moduleItem.totalPercentage}
                        starttime={moduleItem.starttime}
                        endtime={moduleItem.endtime}
                        totalDuration={moduleItem.totalDuration}
                        currentScheduler={moduleItem.currentScheduler}
                      />
                      <PatternList data={moduleItem.patternList} />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="p-col-12">
          <div className="card">
            <div>
              <h2>
                <b>Last Executed Scheduler Report: </b>
                <i>
                  {this.state.schedlerItems &&
                    this.state.schedlerItems.length > 0 &&
                    this.state.schedlerItems[0].schedulerName}
                </i>
              </h2>
            </div>
            <div className="sweet-loading p-col-8">
              <div
                style={{
                  marginLeft: "40em",
                  marginTop: "0.3rem",
                  marginbottom: "0.3rem",
                }}
              >
                <PropagateLoader
                  color="#00aaad"
                  size={25}
                  loading={this.state.loading}
                  css={override}
                />
              </div>
            </div>
            <DataTable
              value={this.state.tableData}
              rows={10}
              editMode="cell"
              rowHover={true}
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={10}
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRight}
              onSelectionChange={(e) =>
                this.setState({ selectedData: e.value })
              }
              columns={renderColumns}
            >
              {this.renderColumns(renderColumns)}
            </DataTable>

            <FailureDialogue
              vresult={this.state.failedRow.verificationResult}
              visible={this.state.visible}
              closeDialog={(e) => this.setState({ visible: false })}
            />
            <SuccessDialog
              vresult={
                this.state.successRow &&
                this.state.successRow.verificationResult
              }
              visible={this.state.visibleSuccess}
              closeDialog={(e) => this.setState({ visibleSuccess: false })}
            />
            {/* <Dialog
            header="Output"
            visible={this.state.visibleSuccess}
            style={{ width: '50vw', wordBreak: "break-word", whiteSpace: "break-spaces" }}
            maximizable={true}
            onHide={() => this.setState({ visibleSuccess: false })}>
            {
              this.state.successRow &&
              this.state.successRow.verificationResult &&
              this.state.successRow.verificationResult.length > 0 &&
              this.state.successRow.verificationResult.map((type, index) => (

                <>
                  <table class="remarkstable">
                    <tbody class="tableBody">
                      <tr>
                        <td>{type.message}<br /></td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ))
            }
          </Dialog> */}

            <Dialog
              id="screenShotDlg"
              header="Screen shots"
              visible={this.state.screenShotDlg}
              style={{ width: "90vw" }}
              modal={true}
              onHide={(e) => this.setState({ screenShotDlg: false })}
              maximizable={true}
            >
              <div className="card">
                <Carousel
                  value={this.screenShotResult}
                  numVisible={1}
                  numScroll={1}
                  responsiveOptions={responsiveOptions}
                  style={{ width: "82em" }}
                  itemTemplate={screenShotTemplateCaraousel}
                />
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(CasUserDashboard);

export const ErrorConstants = {
  CAS_GUI_ERR_01: "CAS_GUI_ERR_01",
  CAS_GUI_ERR_02:"Please Select a value.",
  CAS_GUI_ERR_INV_LOGIN: "CAS_GUI_ERR_INV_LOGIN",
  ADD_USER_ERR: "Failed to add user",
  UPDATE_USER_ERR: "Failed to update user",
  DELETE_USER_ERR: "Failed to delete user",
  RE_EXECUTE_ERR: "Internal Error. Failed to Re-Execute",
  RE_EXECUTE_FETCH_APPS_ERR : "No applications found for Re-Execution",
  GET_LOGS_ERR: "Failed to Get the logs"
};

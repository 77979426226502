import { AuthService } from "./AuthService";
import axios from "axios";
import { ErrorConstants } from "../constants/constants";
import {APIConstants} from "../constants/APIConstants";

export const DashBoardService = {
  getEnviormentDetails,
  updateDashBoard, 
  getDashBoardResultDetails
};

function getEnviormentDetails() {
  AuthService.setupAxiosInterceptor();
  return axios
    .get(
      `${APIConstants.GET_LAST_EXECUTED_APP}`)
    .then((res) => {
      if (res) {
        return Promise.resolve(res.data);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
    });
}

function updateDashBoard(appId) {

  AuthService.setupAxiosInterceptor();
  return axios
    .get( APIConstants.FETCH_DASHBOARD_INFO + "?appId=" + appId)
    .then((res) => {
      if (res) {
        return Promise.resolve(res.data);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
    });
}

function getDashBoardResultDetails(param) {
  AuthService.setupAxiosInterceptor();
  return axios
    .post(APIConstants.FETCH_DASHBOARD_TABLE, param, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res) {
        return Promise.resolve(res.data);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
    });
}
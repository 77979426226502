import axios from "axios";
import { ErrorConstants } from "../constants/constants";
import { AuthService } from "./AuthService";
import {APIConstants} from "../constants/APIConstants";

export const ReportService = {
  getAllCancellationInfo,
  searchCancellationInfo,
  approveCancellationInfos,
  rejectCancellationInfos,
  updateCancellationReasons,
  getEnviormentDetails,
  getScheduleDetails,
  getModuleDetails,
  getPatternDetails,
  getTestResultDetails,
  getReportBlobData,
  getTestScreenShots
};

function getEnviormentDetails (){
  AuthService.setupAxiosInterceptor();
  return axios
  .get(
    `${APIConstants.FETCH_APP_LIST}`)
  .then((res) => {
    if (res ){
     return Promise.resolve(res.data);
    } else {
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    }
  })
  .catch((err) => {
    return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
  });
}

function getScheduleDetails (id){
  AuthService.setupAxiosInterceptor();
  return axios
  .get(
    `${APIConstants.FETCH_SCHEDULER_LIST}?appId=${id}`)
  .then((res) => {
    if (res ){
     return Promise.resolve(res.data);
    } else {
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    }
  })
  .catch((err) => {
    return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
  });
}


function getModuleDetails (param){
  AuthService.setupAxiosInterceptor();
  return axios
  .get(
    `${APIConstants.FETCH_MODULE_LIST}?appId=${param.appId}&&schedulerId=${param.schedulerId}`)
  .then((res) => {
    if (res ){
     return Promise.resolve(res.data);
    } else {
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    }
  })
  .catch((err) => {
    return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
  });
}


function getPatternDetails (param){
  AuthService.setupAxiosInterceptor();
  return axios
  .get(
    `${APIConstants.FETCH_PATTERN_LIST}?moduleId=${param.moduleId}&&schedulerId=${param.schedulerId}`)
  .then((res) => {
    if (res ){
     return Promise.resolve(res.data);
    } else {
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    }
  })
  .catch((err) => {
    return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
  });
}

function getReportBlobData(link){
  let url = `${APIConstants.READ_FILE_AS_BLOB}?path=${link}`;
  return axios.get(url, {
    responseType: "blob",
    headers:  {
      "Content-Type": "application/json",
    },
  }).then((res) =>{
    return Promise.resolve(res.data);
  });
}


function getTestResultDetails (param){
  AuthService.setupAxiosInterceptor();
  return axios
  .post(`${APIConstants.FETCH_TEST_RESULTS}`,param,{
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((res) => {
    if (res ){
     return Promise.resolve(res.data);
    } else {
      
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    }
  })
  .catch((err) => {
    return Promise.resolve(err);
  });
}

function getAllCancellationInfo(callbackFn) {
  AuthService.setupAxiosInterceptor();
  return axios
    .get("http://localhost:8081/cas-gui/cancel-flight")
    .then((res) => {
      if (res.data && res.data.length) {
        if (callbackFn) {
          callbackFn(res.data);
        }
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
    });
}

function searchCancellationInfo(data) {
  AuthService.setupAxiosInterceptor();
  return axios
    .post("http://localhost:8081/cas-gui/cancel-flight/search", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200 && res.data) {
        return Promise.resolve(res.data);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.resolve(403);
    });
}

function approveCancellationInfos(data) {
  AuthService.setupAxiosInterceptor();
  return axios
    .post("http://localhost:8081/cas-gui/cancel-flight/approve", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 204) {
        return Promise.resolve(res.status);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.resolve(403);
    });
}

function rejectCancellationInfos(data) {
  AuthService.setupAxiosInterceptor();
  return axios
    .post("http://localhost:8081/cas-gui/cancel-flight/reject", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 204) {
        return Promise.resolve(res.status);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.resolve(403);
    });
}

function updateCancellationReasons(data) {
  AuthService.setupAxiosInterceptor();
  return axios
    .post("http://localhost:8081/cas-gui/cancel-flight/updateReason", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 204) {
        return Promise.resolve(res.status);
      } else {
        let error = [ErrorConstants.CAS_GUI_ERR_01];
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.resolve(403);
    });
}

/**
 * API Function to fetch the TEST Result Screenshots
 */
function getTestScreenShots (resultId){
  AuthService.setupAxiosInterceptor();
  return axios
  .get(
    `${APIConstants.FETCH_SCREENSHOTS}${resultId}`)
  .then((res) => {
    if (res ){
     return Promise.resolve(res.data);
    } else {
      let error = [ErrorConstants.CAS_GUI_ERR_01];
      return Promise.reject(error);
    }
  })
  .catch((err) => {
    return Promise.reject([ErrorConstants.CAS_GUI_ERR_01]);
  });
}

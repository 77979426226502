import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';

/**
 * @author IBS Software
 */
class SuccessDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maximumCharacter: 500,
            data: {}
        };
    }

    /**
     * Method to handle Read More/ Read Less button click
     * @param {*} key 
     */
    clickReadMoreLessButton = (key) => {
        const { data } = this.state
        if (data[key] == undefined || data[key] == false) {
            data[key] = true
            this.setState({
                data
            })
        } else {
            data[key] = false
            this.setState({
                data
            })
        }
    }

    /**
     * Method to get the table data template with more and less button
     * @param {*} value 
     * @param {*} key 
     * @returns 
     */
    getTableDataTemplate = (value, key) => {
        const { data, maximumCharacter } = this.state
        const canShowMoreValue = data[key]
        if (value &&
            value.length < maximumCharacter) {
            return value;
        } else if (value.length > 0) {
            const lessValue = value.substring(0, maximumCharacter);
            return <div>
                {
                    canShowMoreValue ? value : lessValue
                }
                <div>
                    <Button
                        label={canShowMoreValue ? "Read Less" : "Read More"}
                        className="p-button-link"
                        onClick={(e) => this.clickReadMoreLessButton(key)}
                    />
                </div>
            </div>
        } else {
            return <>-</>
        }
    }

    closeDialog = (e) => {
        this.setState({
            data: {}
        })
        this.props.closeDialog(e)
    }

    render() {
        return <div className="failureDialog">
            <Dialog
                id='verificationDlg'
                header='Output'
                visible={this.props.visible}
                modal={true}
                onHide={e => this.closeDialog(e)}
                maximizable={true}
                style={{ width: '80vw' }}
            >
                <table class="remarkstable">
                    <thead style={{ position: "sticky", top: '-15px', background: '#b3c6ff' }}>
                        <tr >
                            <th colspan="3" >Remarks</th>
                        </tr>
                    </thead>
                    <tbody class="tableBody">
                        {
                            this.props.vresult &&
                                this.props.vresult.length > 0 ?
                                this.props.vresult.map((type, index) => (
                                    <tr >
                                        <td colspan="3" style={{ wordBreak: "break-word" }}>{this.getTableDataTemplate(type.message, index + 'rem')}</td>
                                    </tr>
                                )) :
                                <tr>
                                    <td colspan="3" style={{ wordBreak: "break-word" }}>Incomplete Execution</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </Dialog>
        </div>
    }
}

export default withTranslation("translations")(SuccessDialog);
import url from '../config/url.json'
const REPORT_BASE_URL= url.localhostURL + "/reporterapp2/report";
export const APIConstants = {
    FETCH_ALL_CONFIG: `${REPORT_BASE_URL}/fetchAllConfiguration`,
    FETCH_CONFIG_BY_NAME: `${REPORT_BASE_URL}/fetchConfigurationByAppName`,
    UPDATE_CONFIG: `${REPORT_BASE_URL}/updateConfiguration`,
    DELETE_CONFIG: `${REPORT_BASE_URL}/deleteConfiguration`,
    ADD_CONFIG: `${REPORT_BASE_URL}/addConfiguration`,
    GET_LAST_EXECUTED_APP: `${REPORT_BASE_URL}/fetchLastExecutedApp`,
    FETCH_APP_LIST: `${REPORT_BASE_URL}/fetchAppList`,
    FETCH_SCHEDULER_LIST: `${REPORT_BASE_URL}/fetchSchedulerList`,
    FETCH_MODULE_LIST: `${REPORT_BASE_URL}/fetchModuleList`,
    FETCH_PATTERN_LIST: `${REPORT_BASE_URL}/fetchPatternList`,
    READ_FILE_AS_BLOB: `${REPORT_BASE_URL}/readFileAsBlob`,
    FETCH_TEST_RESULTS: `${REPORT_BASE_URL}/v2/fetchTestResults`,
    ADD_USER: `${REPORT_BASE_URL}/addUser`,
    UPDATE_USER: `${REPORT_BASE_URL}/updateUser`,
    DELETE_USER: `${REPORT_BASE_URL}/deleteUser`,
    FETCH_ALL_USER: `${REPORT_BASE_URL}/fetchAllUserList`,
    RE_EXECUTE_APPLICATION: `${REPORT_BASE_URL}/executeTest`,
    GET_SCHEDULER_DETAILS: `${REPORT_BASE_URL}/schedulerDetails`,
    DELETE_SCHEDULER: `${REPORT_BASE_URL}/deleteScheduler`,
    FETCH_REEXECUTE_APP_DETAILS: `${REPORT_BASE_URL}/fetchReExecuteAppDetails`,
    UPDATE_EXECUTION_STATUS: `${REPORT_BASE_URL}/updateExecutionStatus`,
    STOP_EXECUTION: `${REPORT_BASE_URL}/stopTest`,
    CAS_GUI_UPLOAD: `${REPORT_BASE_URL}/cas-gui/upload`,
    AUTHENTICATE: `${REPORT_BASE_URL}/authenticate`,
    RESET_PASSWORD: `${REPORT_BASE_URL}/resetPassword`,
    FORGOT_PASSWORD: `${REPORT_BASE_URL}/forgotPassword`,
    FETCH_DASHBOARD_INFO: `${REPORT_BASE_URL}/fetchDashboardInfo`,
    FETCH_DASHBOARD_TABLE: `${REPORT_BASE_URL}/v2/fetchDashBoardTable`,
    GET_LOGS: `${REPORT_BASE_URL}/getLogsFromEC2`,
    DOWNLOAD_LOG_FILE: `${REPORT_BASE_URL}/downloadLog`,
    GET_LOGS_EKS: `${REPORT_BASE_URL}/getLogsFromEKS`,
    DOWNLOAD_LOG_FILE_EKS: `${REPORT_BASE_URL}/downloadLogsEks`,
    CAS_CANCEL_FLIGHT: `${REPORT_BASE_URL}/cas-gui/cancel-flight`,
    CAS_SEARCH_FLIGHT: `${REPORT_BASE_URL}/cas-gui/cancel-flight/search`,
    CAS_APPROVE_FLIGHT: `${REPORT_BASE_URL}/cas-gui/cancel-flight/approve`,
    CAS_REJECT_FLIGHT: `${REPORT_BASE_URL}/cas-gui/cancel-flight/reject`,
    CAS_UPDATE_REASON: `${REPORT_BASE_URL}/cas-gui/cancel-flight/updateReason`,
    FETCH_SCREENSHOTS : `${REPORT_BASE_URL}/fetchScreenShots?resultId=`
    
  };
import React, { Component } from "react";
import { Card } from "primereact/card";
import CasButton from "../common/formfields/CasButton";
import CasInputText from "../common/formfields/CasInputText";
import CasPassword from "../common/formfields/CasPassword.jsx";
import PropTypes from "prop-types";
import { AuthService } from "../../service/AuthService";
import CustomTranslate from "../common/translate/CustomTranslate";
import { withTranslation } from "react-i18next";
import config from '../../config/url.json';
import "./LoginScreen.css";

/* Login Page Component */
class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userNameError: "",
      password: "",
      passwordError: "",
      invalidLogin: "",
    };
    this.onChange = this.onChange.bind(this);
    this.loginToApplication = this.loginToApplication.bind(this);
  }
  static propTypes = {
    onLogin: PropTypes.func
  };

  //Initial Focus
  componentDidMount(){
    document.getElementById("userName").focus(); 
  }
  //handle event change
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validateInputs() {
    const { t } = this.props;
    let label = t("login", { returnObjects: true });
    let userNameError = "";
    let passwordError = "";
    if (!this.state.userName) {
      userNameError = label.userNameError;
    }
    if (!this.state.password) {
      passwordError = label.passwordError;
    }
    this.setState({
      userNameError: userNameError,
      passwordError: passwordError,
    });
    if (userNameError || passwordError) {
      return false;
    }
    return true;
  }

  loginToApplication = () => {
    const { t } = this.props;
    let label = t("login", { returnObjects: true });
    if (this.validateInputs()) {
      AuthService.authenticate(this.state.userName, this.state.password)
        .then((token) => {
          if (this.props.onLogin) {
            this.props.onLogin();
          }
        })
        .catch((error) => {
          this.setState({
            invalidLogin: label.invLoginError,
          });
        });
    }
  };

  handleForgotPassword = () => {
    this.props.history.push("/reset-password");
  };

  render() {
    /** t function will help us in extracting labels
     *  based on the locale or language chosen */
    const { t } = this.props;
    let label = t("login", { returnObjects: true });

    const header = (
      <img alt="Card" src={require("../../assets/images/app_logo.png")} />
    );

    const footer = (
      <div className="p-grid">
        <CasButton
          id="signIn"
          onClick={this.loginToApplication}
          label={label.signIn}
          className="p-col-12"
          icon="pi pi-sign-in"
        />
        <div
          style={{ color: '#00aaad', cursor: 'pointer', marginLeft: '100px', marginBottom: '5px' }}
          onClick={this.handleForgotPassword} tabIndex={4}
        >
          Forgot Password?
        </div>
        <div className="rt-powered-by-container p-m-auto p-d-flex p-ai-center">
          <span>Powered By</span>
          <img
            src={require("../../assets/images/rapidtest_logo.png")}
            alt="rapid test"
          />
          
        </div>
        <div style={{marginLeft: '115px'}}>
          <span>Version: {config.version}</span>
        </div>
        {/*
        <CasButton
          id="forgotPass"
          onClick={this.forgotPassword}
          label={label.forgPass}
          icon="pi pi-question-circle"
          className="p-button-danger p-col-12"
        />
         <div className="login-lang-change">
          <CustomTranslate className="translate-btn translate-login-btn" />
        </div> */}
      </div>
    );

    return (
      <div className="login-screen">
        <Card
          title=""
          subTitle={this.state.invalidLogin}
          style={{ width: "27em", border: "0.1px solid #004164" }}
          className="login-screen__card ui-card-shadow"
          footer={footer}
          header={header}
        >
          <div className="p-grid">
            <span>
              <i
                style={{
                  marginTop: ".5rem",
                  marginRight: ".6rem",
                  fontSize: "1.5rem",
                }}
                className="pi pi-user"
              />
            </span>
            <CasInputText
              id="userName"
              placeholder={label.userName}
              name="userName"
              value={this.state.userName}
              onChange={this.onChange}
              className="p-inputtext-lg p-d-block"
              errorText={this.state.userNameError}
            />

            <span>
              <i
                style={{
                  marginTop: ".5rem",
                  marginRight: ".6rem",
                  fontSize: "1.5rem",
                }}
                className="pi pi-lock"
              />
            </span>
            <CasPassword
              id="password"
              placeholder={label.pass}
              name="password"
              value={this.state.password}
              onChange={this.onChange}
              className="p-inputtext-lg p-d-block"
              errorText={this.state.passwordError}
            />
          </div>
        </Card>
      </div>
    );
  }
}

export default withTranslation("translations")(LoginScreen);

import React, { Component } from "react";
import CasTopbar from "../common/topbar/CasTopbar";
import CasMainMenu from "../common/menu/CasMainMenu";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import CasLoginScreen from "../login/LoginScreen";
import CasLoginUserProfile from "../common/userprofile/CasLoginUserProfile";
import CasReasonApproval from "../report/RapidTestReport";
import classNames from "classnames";
import { ApplicationConstants } from "../../constants/constants";
import { Dialog } from "primereact/dialog";
import CasButton from "../common/formfields/CasButton";
import { AuthService } from "../../service/AuthService";
import { withTranslation, Trans } from "react-i18next";
import IdleTimer from "react-idle-timer";
import CasUserDashboard from "../dashboard";
import YAMLConfiguration from "../configuration/YAMLConfiguration";
import UserDetails from "../manageUser/ManageUser";
import ResetPasswordScreen from "../login/PasswordReset";
import ReExecuteApplication from '../reExecuteApplication';


/* Home page for users */
class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staticMenuInactive: false,
      sharedObject: null,
      logoutDialog: false,
      timeOutDialog: false,
    };
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.logOutAction = this.logOutAction.bind(this);
    this.hideLogOutDialog = this.hideLogOutDialog.bind(this);
    this.logOutConfirm = this.logOutConfirm.bind(this);
    this.hideTimeOutDialog = this.hideTimeOutDialog.bind(this);
    this.timeOutConfirm = this.timeOutConfirm.bind(this);
    this.loginAction = this.loginAction.bind(this);
    this.autoLogOut = this.autoLogOut.bind(this);
  }

  onToggleMenu() {
    this.setState({
      staticMenuInactive: !this.state.staticMenuInactive,
    });
  }

  logOutConfirm() {
    this.setState({ logoutDialog: true });
  }

  timeOutConfirm() {
    this.setState({ timeOutDialog: true });
    this.autoLogOut();
  }

  /** Auto logout  within 10 sec if user remains idle
   * even after session time out */
  autoLogOut() {
    setTimeout(() => {
      if (this.state.timeOutDialog) {
        this.logOutAction();
      }
    }, 10000); // 10000ms = 10sec
  }

  /* Clear the user session and redirect to login page */
  logOutAction() {
    sessionStorage.setItem(ApplicationConstants.CAS_LOGIN_STAT, false);

    sessionStorage.removeItem(ApplicationConstants.CAS_USER_TOKEN);
    sessionStorage.removeItem(ApplicationConstants.USER_NAME_KEY);
    sessionStorage.removeItem(ApplicationConstants.NAME_KEY);
    sessionStorage.removeItem(ApplicationConstants.NAME_KEY_JP);
    sessionStorage.removeItem(ApplicationConstants.USER_ROLES_KEY);
    sessionStorage.removeItem(ApplicationConstants.USER_ROLES_KEY);

    this.hideLogOutDialog();
    this.hideTimeOutDialog();

    this.props.history.push("/login");
    window.location.reload();
  }

  loginAction() {
    sessionStorage.setItem(ApplicationConstants.CAS_LOGIN_STAT, true);
    this.props.history.push("/home");
  }

  hideLogOutDialog() {
    this.setState({ logoutDialog: false });
  }

  hideTimeOutDialog() {
    this.setState({ timeOutDialog: false });
  }

  render() {
    const { t } = this.props;
    let logoutLabel = t("logOut", { returnObjects: true });
    let timeoutLabel = t("timeOut", { returnObjects: true });
    let logginStat = sessionStorage.getItem(
      ApplicationConstants.CAS_LOGIN_STAT
    );

    /* if user is logged in */
    if (logginStat === "true") {
      const privileges = AuthService.getPrivileges();
      let menu = [];
      let dashboardMenu = {
        label: <Trans>{t("dashboard")}</Trans>,
        icon: "pi pi-fw pi-home",
        to: "/home",
      };
      
      let configMenu = {
        label: "Configuration",
        icon: "pi pi-fw pi-cog",
        to: "/configuration",
      };
      
        let manageUser = {
          label: "Manage Users",
          icon: "pi pi-fw pi-user",
          to: "/manageUser",
        };

        let reExecuteApplication = {
          label: "Execution Info",
          icon: "pi pi-replay",
          to: "/reExecuteApplication",
        };
      

   
      // let flightCancellationMenu = {
      //   label: <Trans>{t("flightCancel")}</Trans>,
      //   icon: " pi pi-fw pi-bars",
      //   items: [
      //     {
      //       label: <Trans>{t("reasonApproval")}</Trans>,
      //       icon: "pi pi-fw pi-eye",
      //       to: "/report",
      //     },
      //   ],
      // };

      let flightCancellationMenu = {
        label: <Trans>{t("reasonApproval")}</Trans>,
        icon: "pi pi-fw pi-eye",
        to: "/report"
      };

      /* Load the menu options based on the user rights or privileges */
     
        menu.push(dashboardMenu);
        menu.push(flightCancellationMenu);
        menu.push(configMenu);
        if (localStorage.getItem('userRole').includes('ADMIN')) {
          menu.push(manageUser);
        }
        menu.push(reExecuteApplication);
        
     
      const wrapperClass = classNames("layout-wrapper", "layout-static", {
        "layout-static-sidebar-inactive": this.state.staticMenuInactive,
      });

      const sidebarClassName = classNames("layout-sidebar layout-sidebar-dark");

      const logOutDialogFooter = (
        <React.Fragment>
          <CasButton
            label={logoutLabel.no}
            icon="pi pi-times"
            className="p-button-text"
            onClick={this.hideLogOutDialog}
          />
          <CasButton
            label={logoutLabel.yes}
            icon="pi pi-check"
            className="p-button-text"
            onClick={this.logOutAction}
          />
        </React.Fragment>
      );

      const timeOutDialogFooter = (
        <React.Fragment>
          <CasButton
            label={timeoutLabel.no}
            icon="pi pi-times"
            className="p-button-text"
            onClick={this.logOutAction}
          />
          <CasButton
            label={timeoutLabel.yes}
            icon="pi pi-check"
            className="p-button-text"
            onClick={this.hideTimeOutDialog}
          />
        </React.Fragment>
      );

      return (
        <div className={wrapperClass}>
          {/* Check if the user actions like mouse click, keyboard inputs etc. 
              Remains idle for specified time out, if yes prompt user with timeout message  */}

          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={ApplicationConstants.CAS_SESSION_TIME}
            onIdle={this.timeOutConfirm}
            debounce={250}
          >
            {/* Top menu component */}
            <CasTopbar
              onToggleMenu={this.onToggleMenu}
              onLogOut={this.logOutConfirm}
              staticMenuInactive={this.state.staticMenuInactive}
              {...this.props}
            />
            

            {/* Side menu component */}
             <div className={sidebarClassName}>
              {/* <CasLoginUserProfile /> */}
              <CasMainMenu model={menu} />
            </div> 

            <div className="layout-main">
              {/* Router loads different pages based on request path or url */}
              <Switch>
              <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/login">
                  <Redirect to="/home" />
                </Route>
            
                <Route
                  path="/report"
                  component={CasReasonApproval}
                />

                <Route
                  path="/home"
                  component={CasUserDashboard}
                />

                 <Route
                  path="/configuration"
                  component={YAMLConfiguration}
                />
                 <Route
                  path="/manageUser"
                  component={UserDetails}
                />
                <Route
                  path="/reExecuteApplication"
                  component={ReExecuteApplication}
                />
              </Switch>
            </div>

            {/* Logout Message Template */}
            <Dialog
              className="cas-dialog"
              visible={this.state.logoutDialog}
              style={{ width: "30rem" }}
              header={logoutLabel.header}
              modal
              footer={logOutDialogFooter}
              onHide={this.hideLogOutDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={{ fontSize: "2rem" }}
                />
                <span>{logoutLabel.confirmMsg}</span>
              </div>
            </Dialog>

            {/* Timeout Message Template */}
            <Dialog
              className="cas-dialog"
              visible={this.state.timeOutDialog}
              style={{ width: "30rem" }}
              header={timeoutLabel.header}
              modal
              footer={timeOutDialogFooter}
              onHide={this.hideTimeOutDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={{ fontSize: "2rem" }}
                />
                <span
                  dangerouslySetInnerHTML={{ __html: timeoutLabel.confirmMsg }}
                ></span>
              </div>
            </Dialog>
          </IdleTimer>
        </div>
      );
    } else {
    /* User is not logged in  redirect to login page */
      return (
        <div>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route
            path="/reset-password"
            render={(props) => (
              <ResetPasswordScreen/>
            )}
          />
          <Route
            path="/login"
            render={(props) => (
              <CasLoginScreen onLogin={this.loginAction} {...props} />
            )}
          />
          <Redirect from="*" to="/login" />
        </div>
      );
    }
  }
}

export default withRouter(withTranslation("translations")(HomeScreen));
/* withRouter has been used to check the login status during each page requests 
   in case user tries to access the page url without login, will be redirect to login page
*/

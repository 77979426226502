import React from "react";
import ReactDOM from "react-dom";
import ReportApp from "./ReportApp";

/**
 * Render cas-gui-app to the DOM or HTML page
 * id="root" is present in public/index.html */
ReactDOM.render(<ReportApp />,
    document.getElementById("root"));


import React from "react";
import { withTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ReExecutionService } from '../../service/ReExecutionService.js';
import { Toast } from "primereact/toast";
import { InputText } from 'primereact/inputtext';
import CasButton from "../common/formfields/CasButton";
import { Dialog } from "primereact/dialog";
import { PropagateLoader } from 'react-spinners';
import { css } from "@emotion/react";

class ReExecuteApp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            appConfigurationList: [],
            schedulerJob: [],
            showMessage: false,
            globalFilter: "",
            currentAppName: "",
            showStopDialog: false,
            loading: false,
            showSearchMessage: "",
            params: []
        }
    }

    componentDidMount = () => {
        this.fetchApplications();
    }

    fetchApplications = () => {
        this.setState({
            showMessage: false,
            loading: true
        })
        let appConfigurationList = [];
        ReExecutionService.fetchReExecuteAppDetails().then(
            (res) => {
                if (res.length > 0) {
                    res.forEach((item) => {
                        appConfigurationList.push({
                            label: item.appName,
                            status: item.executionId == 0 ? "Completed" : "In Progress",
                            lastExecuted: item.schedulerName,
                            appName: item.appName,
                            appId: item.appId

                        });
                        this.setState({
                            appConfigurationList,
                            loading: false
                        });
                    })
                } else {
                    this.setState({
                        loading: false,
                        showSearchMessage: "*No application records found"
                    })
                }

            },
            (err) => {
                this.setState({
                    loading: false
                })
                this.toast && this.toast.show({
                    severity: "error",
                    summary: "Error",
                    detail: err,
                    life: 3000,
                });
            }
        );

    }

    getColumnDetails = (columnList) => columnList.map(element => {
        if (element.field == "status") {
            return <Column
                header="Execution Status"
                headerStyle={{ width: '15rem' }}
                body={(data) => this.getStatusIcon(data)}
            />
        } else {
            return <Column key={element.field} field={element.field} header={element.header} />
        }

    });

    getStatusIcon = (data) => {
        return <p style={{ color: data.status == "Completed" ? "green" : "red" }} >
            <i
                style={{ marginRight: "10px" }}
                class={data.status == "Completed" ? "pi pi-check" : "pi pi-spin pi-spinner"} > </i>
            {data.status}
        </p>
    }

    getActionButton = (appDetails) => {
        return <>
            {
                appDetails.status == "Completed" ?
                    <Button
                        className="p-button"
                        label="Re-Execute"
                        disabled={appDetails.status == "Completed" ? false : true}
                        onClick={() => this.reExecuteApp(appDetails)}
                    /> :
                    <Button
                        className="p-button-danger"
                        label="Stop Execution"
                        disabled={appDetails.status == "Completed" ? true : false}
                        onClick={() => this.stopExecution(appDetails)}
                    />
            }

        </>
    };

    stopExecution = (appDetails) => {
        this.setState({
            currentAppName: appDetails.appName,
            showStopDialog: true
        })
    }

    callStopExecution = () => {
        this.setState({
            showStopDialog: false,
            loading: true
        })
        ReExecutionService.stopExecution(this.state.currentAppName)
            .then((res) => {
                this.fetchApplications();
                this.setState({
                    loading: false
                })
                if (res.status == 200) {
                    this.toast && this.toast.show({
                        severity: "warn",
                        summary: "Stopped",
                        detail: res.data.message + ". Application name is : " + res.data.applicationName,
                        life: 5000,
                    });
                } else {
                    this.toast && this.toast.show({
                        severity: "error",
                        summary: "Error ",
                        detail: res.data.message + ". Application name is : " + res.data.applicationName,
                        life: 5000,
                    });
                }
            })
    }

    handleChangeRes = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }


    reExecuteApp = (appDetails) => {

        ReExecutionService.updateExecuteStatus(appDetails.appId, appDetails.appName)
            .then((res) => {
                if (res) {
                    this.fetchApplications();
                    const params = {
                        testImageName: appDetails.appName,
                        paramterList: []
                    }
                    ReExecutionService.reExecute(params).then(
                        (res) => {
                            this.fetchApplications();
                            if (res.status && res.status == 200) {
                                this.toast && this.toast.show({
                                    severity: "success",
                                    summary: "Success",
                                    detail: res.data.message + ". Test application is : " + res.data.applicationName,
                                    life: 5000,
                                });
                            } else if (res != null) {
                                this.toast && this.toast.show({
                                    severity: "error",
                                    summary: "Error",
                                    detail: res.data.message + ". Test application is : " + res.data.applicationName,
                                    life: 5000,
                                });
                            } else {
                                this.toast && this.toast.show({
                                    severity: "error",
                                    summary: "Error",
                                    detail: res.data && res.data.message ? res.data.message : "UnExpected Error!",
                                    life: 5000,
                                });
                            }
                        }
                    )
                } else {
                    this.toast && this.toast.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Authentication Failed. Server Configuration Error!",
                        life: 5000,
                    });
                }
            })
    }

    handleRequestClose = () => {
        this.setState({
            showStopDialog: false
        });
    };


    render() {

        const deleteDialogFooter = (
            <React.Fragment>
                <CasButton
                    label="No"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.handleRequestClose}
                />
                <CasButton
                    label="Yes"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.callStopExecution}
                />
            </React.Fragment>
        );

        const columnList = [
            { field: 'label', header: 'Test Application Name' },
            { field: 'status', header: 'Execution Status' },
            { field: 'lastExecuted', header: 'Last Executed Schedule' }
        ];

        const override = css`display: inline;
        margin: 1.4rem;
        `;

        return <div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '10px' }}>
                <h3>Re-Execute Application</h3>
                <div>
                    <span className="p-input-icon-right float-right">
                        <i className="pi pi-search" />
                        <InputText
                            onChange={this.handleChangeRes}
                            name="globalFilter"
                            placeholder={"Search"}
                            value={this.state.globalFilter}
                            disabled={!this.state.appConfigurationList}
                        />
                    </span>
                </div>
                <div style={{ marginRight: '100px' }}><Button
                    className="p-button"
                    label="Refresh"
                    onClick={() => this.fetchApplications()}
                > <i style={{ marginLeft: "1rem" }} class="pi pi-refresh"></i> </Button></div>
            </div>
            <div style={{ marginLeft: "45%", marginBottom: "8px", marginTop: "0.2rem" }}>
                <PropagateLoader color="#00aaad" size={22} loading={this.state.loading} css={override} />
            </div>
            {
                this.state.appConfigurationList &&
                this.state.appConfigurationList.length > 0 &&
                <DataTable
                    value={this.state.appConfigurationList}
                    rows={10}
                    editMode="cell"
                    rowHover={true}
                    paginator={true}
                    globalFilter={this.state.globalFilter}
                >
                    {this.getColumnDetails(columnList)}
                    <Column
                        header="Action"
                        headerStyle={{ width: '15rem' }}
                        body={(data) => this.getActionButton(data)}
                    />
                </DataTable>
            }
             {
                this.state.showSearchMessage &&
                <div style={{ color: "red", display: 'flex', marginTop: "10px" }}>
                    {this.state.showSearchMessage}
                </div>
            }
            {this.state.showStopDialog &&
                <Dialog
                    className="cas-dialog"
                    visible={this.state.showStopDialog}
                    style={{ width: "30rem" }}
                    header="Confirm"
                    modal={true}
                    footer={deleteDialogFooter}
                    onHide={() => this.handleRequestClose()}
                >
                    <div style={{ display: "flex" }} className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: "2rem" }}
                        />
                        <span style={{ marginTop: "5px" }}>{`Are you sure you want to stop the execution for application name : ${this.state.currentAppName}`}</span>
                    </div>
                </Dialog>
            }
            <Toast ref={(el) => (this.toast = el)} />
        </div>
    }
}

export default withTranslation("translations")(ReExecuteApp);
import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { withTranslation } from 'react-i18next';
/**
 * @authorGowthaman A
 * @description Component is to render the Drop Down with filter option
 */
class FilterDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: this.props.value
        }
        this.selectedTemplate = this.selectedTemplate.bind(this)
        this.optionTemplate = this.optionTemplate.bind(this)
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({ selectedValue: this.props.value })
        }
    }
    handleChange(value) {
        this.setState({ selectedValue: value })
        return value
    }
    selectedTemplate(option, props) {
        if (option) {
            return (
                <div>{option.label}​​​</div>
            );
        }
        return (
            <span>{props.placeholder}​​​</span>
        );
    }
    optionTemplate(option) {
        return (
            <div>{option.label}​​​</div>
        );
    }
    render() {

        const {
            label,
            labelDesc,
            labelClass,
            options,
            isRequired,
            id,
            onChange,
            testIdentifier,
            info,
            error,
            enabled,
            style,
            placeholder
        } = this.props

        const { selectedValue } = this.state
        return (
            <div className="form-group">
                {/* <label htmlFor={id} className={labelClass ? labelClass : "d-block"}>
                    {label}
                    {isRequired &&
                        <span className="text-warning">*</span>
                    }
                    {
                        labelDesc &&
                        <> {labelDesc}</>

                    }
                </label> */}
                <Dropdown
                    inputId={id}
                    className={`${error ? "is-invalid" : ""}`}
                    value={selectedValue}
                    options={options}
                    onChange={(e) => onChange(this.handleChange(e.value))}
                    optionLabel="label"
                    optionValue="value"
                    placeholder={placeholder}
                    aria-describedby={`${id}_help`}
                    data-test={testIdentifier}
                    disabled={!enabled}
                    valueTemplate={this.selectedTemplate}
                    itemTemplate={this.optionTemplate}
                    style={style}
                    filter
                    showClear
                />
                <small id={`${id}_help`} className="form-text text-muted">{info}</small>
            </div>
        )
    }

}
FilterDropDown.defaultProps = {
    label: "",
    isRequired: false,
    options: []
}

export default withTranslation("translations")(FilterDropDown);

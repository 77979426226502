import axios from "axios";
import { ErrorConstants, ApplicationConstants } from "../constants/constants";
import { APIConstants } from '../constants/APIConstants'

export const AuthService = {
  authenticate,
  getUsername,
  getRoles,
  getPrivileges,
  getName,
  getJpName,
  getToken,
  setupAxiosInterceptor,
  handleRecoveryPhraseValidation,
  handlePasswordReset
};

function authenticate(username, password) {
  return axios
    .post(APIConstants.AUTHENTICATE, {
      username,
      password,
    })
    .then((res) => {
      if (res.data) {
        let token = res.data.jwtToken;
        setToken(token);
        setUserDetails(res.data);
        localStorage.setItem('userRole', res.data.userRole);
        localStorage.setItem('displayName', res.data.displayName);
        localStorage.setItem('userName', res.data.userName);
        return Promise.resolve(token);
      } else {
        let error = ErrorConstants.CAS_GUI_ERR_INV_LOGIN;
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.reject(ErrorConstants.CAS_GUI_ERR_INV_LOGIN);
    });
}

function handlePasswordReset(username, password) {
  return axios
    .get( APIConstants.RESET_PASSWORD + `?userName=${username}&resetPassword=${password}`)
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        let error = ErrorConstants.VALIDATE_RECOVERY_PHRASE_ERR;
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.reject(ErrorConstants.VALIDATE_RECOVERY_PHRASE_ERR);
    });
}

function handleRecoveryPhraseValidation(username, recoveryPhrase) {
  return axios
    .get( APIConstants.FORGOT_PASSWORD + `?userName=${username}&recoveryPhrase=${recoveryPhrase}`)
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        let error = ErrorConstants.PASSWORD_REST_ERR;
        return Promise.reject(error);
      }
    })
    .catch((err) => {
      return Promise.reject(ErrorConstants.PASSWORD_REST_ERR);
    });
}


async function setUserDetails(data) {
  // await sessionStorage.setItem(
  //   ApplicationConstants.USER_NAME_KEY,
  //   data.userName
  // );
  // await sessionStorage.setItem(ApplicationConstants.NAME_KEY, data.name);
  // await sessionStorage.setItem(ApplicationConstants.NAME_KEY_JP, data.jpName);
  await sessionStorage.setItem(
    ApplicationConstants.USER_ROLES_KEY,
    data.userRole
  );
  // await sessionStorage.setItem(
  //   ApplicationConstants.USER_PRIVILEGES_KEY,
  //   data.privileges
  // );
  // setupAxiosInterceptor();
}

async function setToken(token) {
  await sessionStorage.setItem(ApplicationConstants.CAS_USER_TOKEN, token);
  setupAxiosInterceptor();
}

function getUsername() {
  return sessionStorage.getItem(ApplicationConstants.USER_NAME_KEY);
}

function isLoggedIn() {
  return getToken() ? true : false;
}

function getToken() {
  return (
    "Bearer " + sessionStorage.getItem(ApplicationConstants.CAS_USER_TOKEN)
  );
}

function getRoles() {
  let roles = sessionStorage.getItem(ApplicationConstants.USER_ROLES_KEY);
  if (roles) {
    return roles;
  }
  return [];
}
function getPrivileges() {
  let privileges = sessionStorage.getItem(
    ApplicationConstants.USER_PRIVILEGES_KEY
  );
  if (privileges) {
    return privileges.split(",");
  }
  return [];
}

function getName() {
  return sessionStorage.getItem(ApplicationConstants.NAME_KEY);
}

function getJpName() {
  return sessionStorage.getItem(ApplicationConstants.NAME_KEY_JP);
}

function setupAxiosInterceptor() {
  axios.interceptors.request.use((config) => {
    if (isLoggedIn()) {
      let headerToken = getToken();
      config.headers.Authorization = headerToken;
      return config;
    }
  });
}

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from "primereact/button";
import FilterDropDown from "../common/formfields/FilterDropDown.jsx";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ReExecutionService } from '../../service/ReExecutionService.js';
import { PropagateLoader } from 'react-spinners';
import { css } from "@emotion/react";
import { InputText } from 'primereact/inputtext';
import url from '../../config/url.json';

/**
 * @name LogInfo 
 * @description The Class to list and download the log files of RTF TestScript
 */
class LogInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            environmentList: [],
            appConfigurationList: [],
            selectedApp: undefined,
            selectedBranch: undefined,
            showLogDetails: false,
            showSearchMessage: undefined,
            loading: false,
            globalFilter: "",
            link: "",
            logEnvironment: ""
        }
    }

    componentDidMount = () => {
        this.getEnviormentDetails();
        this.setState({
            logEnvironment: "run"
        })
    }

    getEnviormentDetails = () => {
        let environmentList = [];
        ReExecutionService.fetchReExecuteAppDetails().then((res) => {
            res.forEach((item) => {
                environmentList.push({ value: item.appName, label: item.appName });
            })
        })
        this.setState({
            environmentList
        })
    }

    getBlobFile = (selectedRowDetails) => {

        let downloadName = selectedRowDetails.logName
        if (selectedRowDetails.logName != "" && selectedRowDetails.logName != undefined) {
            ReExecutionService.downloadLogFile(selectedRowDetails.appName, selectedRowDetails.branchName,
                selectedRowDetails.logName, this.state.logEnvironment).then((result) => {
                    const blob = new Blob([result], { type: 'application/octet-stream' });
                    this.setState({
                        link: URL.createObjectURL(blob)
                    }, () => {
                        //1. Create link with a tag
                        const link = document.createElement('a');
                        //2. Set the href to the link and attributes
                        link.href = this.state.link;
                        link.setAttribute('download', downloadName);
                        // 3. Append to html page
                        document.body.appendChild(link);
                        // 4. Force download
                        link.click();
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link);
                    })
                })
        }
    }

    handleAppChange = (key, value) => {
        this.setState({
            [key]: value,
            showSearchMessage: undefined,
            showLogDetails: false,
            appConfigurationList: []
        })
    }

    getColumnDetails = (columnList) => columnList.map(element => {
        return <Column key={element.field} field={element.field} header={element.header} />
    });

    getDownloadButton = (selectedRowDetails) => {
        return <Button
            className="p-button"
            label="Download"
            disabled={selectedRowDetails.logName.endsWith(".log") ? false : true}
            onClick={() => this.getBlobFile(selectedRowDetails)}
        />
    };

    searchLog = () => {
        const { selectedApp } = this.state;
        if (selectedApp == "" || selectedApp == undefined) {
            this.setState({
                showLogDetails: false,
                showSearchMessage: "*Please select the application"
            })
            return
        }
        this.setState({
            showSearchMessage: undefined,
            loading: true
        })
        let appConfigurationList = [];
        ReExecutionService.getLogs(selectedApp, this.state.logEnvironment).then(
            (res) => {
                if (res.logList &&
                    res.logList.length > 0) {
                    res.logList.forEach((item) => {
                        appConfigurationList.push({ 'logName': item, 'appName': res.applicationName, 'branchName': res.branchName })
                    })
                    this.setState({
                        appConfigurationList,
                        showLogDetails: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        showSearchMessage: "*No logs found for the selected application",
                        loading: false
                    })
                }

            }
        ).catch((error) => {
            this.setState({
                showSearchMessage: error,
                loading: false
            })
        });
    }

    handleChangeRes = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleLogEnvChange = (value) => {
        this.setState({
            logEnvironment: value
        })
    }

    render() {

        const columnList = [
            { field: 'logName', header: 'Log Files' }
        ];

        const dropDownCSS = {
            outline: "0",
            border: "0",
            borderRadius: "3px",
            border: "2px solid #007AD9",
        }

        const override = css`display: inline;
        margin: 1.4rem;
        `;

        const { selectedApp, appConfigurationList, environmentList } = this.state
        return <>
            <div className="p-col-12">
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '15px' }}>
                    <h3>Download Application Log</h3>
                </div>
                {
                    url && url.logEnvironment === "eks" &&
                    <div className="p-grid p-formgrid" style={{ marginBottom: "10px" }}>
                        <div className="p-col-12 p-mb-2 p-lg-1 p-mb-lg-0">
                            <input type="radio" id="logEnvironment1" name="testRun" value="run"
                                onChange={(e) => this.handleLogEnvChange(e.target.value)}
                                checked={this.state.logEnvironment === "run"} />&nbsp;
                        <label htmlFor="logEnvironment1">Test Run</label>
                        </div>
                        <div className="p-col-12 p-mb-3 p-lg-2 p-mb-lg-0" style={{width: "13.6667%"}}>
                            <input type="radio" id="logEnvironment2" name="deployment" value="deploy"
                                onChange={(e) => this.handleLogEnvChange(e.target.value)}
                                checked={this.state.logEnvironment === "deploy"} />&nbsp;
                        <label htmlFor="logEnvironment2">Test Deployment</label>
                        </div>
                        <div >
                            <input type="radio" id="logEnvironment3" name="testStop" value="stop"
                                onChange={(e) => this.handleLogEnvChange(e.target.value)}
                                checked={this.state.logEnvironment === "stop"} />&nbsp;
                        <label htmlFor="logEnvironment3">Test Stop</label>
                        </div>
                    </div>
                }
                <div className="p-grid p-formgrid">
                    <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                        <FilterDropDown
                            label="Application"
                            placeholder="Select the Application"
                            options={environmentList}
                            isRequired={true}
                            id="selectedApp"
                            value={selectedApp}
                            onChange={(e) => this.handleAppChange("selectedApp", e)}
                            testIdentifier="selectedPoints"
                            style={dropDownCSS}
                            info=""
                            enabled={true}
                        />
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <div className="p-col-12 p-mb-2 p-lg-2 p-mb-lg-0">
                            <Button
                                label={"Search"}
                                icon="pi pi-search"
                                onClick={this.searchLog}
                            />
                        </div>
                    </div>
                </div>
                {
                    this.state.showSearchMessage &&
                    <div style={{ color: "red", display: 'flex', marginTop: "10px" }}>
                        {this.state.showSearchMessage}
                    </div>
                }
                <div style={{ marginLeft: "45%", marginTop: "0.2rem" }}>
                    <PropagateLoader color="#00aaad" size={22} loading={this.state.loading} css={override} />
                </div>
                {
                    selectedApp != undefined &&
                    this.state.showLogDetails && <>
                        <div className="p-grid p-formgrid" style={{ marginLeft: "45%" }}>
                            <span className="p-input-icon-right float-right">
                                <i className="pi pi-search" />
                                <InputText
                                    onChange={this.handleChangeRes}
                                    name="globalFilter"
                                    placeholder={"Search"}
                                    value={this.state.globalFilter}
                                    disabled={false}
                                />
                            </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '10px' }}>
                            <DataTable
                                value={appConfigurationList}
                                rows={10}
                                editMode="cell"
                                rowHover={true}
                                paginator={true}
                                columns={columnList}
                                globalFilter={this.state.globalFilter}
                            >
                                {this.getColumnDetails(columnList)}
                                <Column
                                    header="Action"
                                    headerStyle={{ width: '15rem' }}
                                    body={(data) => this.getDownloadButton(data)}
                                />
                            </DataTable>
                        </div></>
                }
            </div>
        </>
    }
}

export default withTranslation("translations")(LogInfo)
import axios from "axios";
import { AuthService } from "./AuthService";
import { ErrorConstants } from "../constants/constants";
import { APIConstants } from "../constants/APIConstants";
import url from '../config/url.json'

export const ReExecutionService = {
    reExecute,
    updateExecuteStatus,
    stopExecution,
    getSchedulerDetailsByAppId,
    deleteSchedulerDetails,
    fetchReExecuteAppDetails,
    getLogs,
    downloadLogFile
};

function reExecute(params) {
    AuthService.setupAxiosInterceptor();
    // const headers = {
    //     'userName': "admin",
    //     'password': "admin"
    // }
    const headers = {
        'userName': url.apiUsername,
        'password': url.apiPassword
    }
    return (
        axios
            .post(`${APIConstants.RE_EXECUTE_APPLICATION}`, params, { headers: headers })
            .then((res) => {
                if (res) {
                    return Promise.resolve(res);
                } else {
                    return Promise.resolve(res);
                }
            })
            .catch((error) => {
                return Promise.resolve(error.response);
            })
    );
}

function updateExecuteStatus(appId, appName) {
    const headers = {
        'userName': url.apiUsername,
        'password': url.apiPassword
    }
    AuthService.setupAxiosInterceptor();
    return (
        axios
            .get(`${APIConstants.UPDATE_EXECUTION_STATUS}` + "?appId=" + appId + "&appName=" + appName, { headers: headers })
            .then((res) => {
                if(res){
                    return Promise.resolve(res.data);
                }
            })
    )
}

function stopExecution(appName) {
    AuthService.setupAxiosInterceptor();
    return (
        axios
            .post(`${APIConstants.STOP_EXECUTION}` + "?appName=" + appName )
            .then((res) => {
                if (res) {
                    return Promise.resolve(res);
                } else {
                    return Promise.resolve(res);
                }
            })
            .catch((error) => {
                return Promise.resolve(error.response);
            })
    );
}

function getSchedulerDetailsByAppId(params) {
    AuthService.setupAxiosInterceptor();
    return (
        axios
            .post(`${APIConstants.GET_SCHEDULER_DETAILS}` + "?appId=" + params)
            .then((res) => {
                if (res) {
                    return Promise.resolve(res.data);
                } else {
                    let error = [ErrorConstants.RE_EXECUTE_ERR];
                    return Promise.reject(error);
                }
            })
            .catch((err) => {
                let error = [ErrorConstants.RE_EXECUTE_ERR];
                return Promise.reject(error);
            })
    );
}

function deleteSchedulerDetails(appId, schedulerList) {
    AuthService.setupAxiosInterceptor();
    return (
        axios
            .post(`${APIConstants.DELETE_SCHEDULER}` + "?appId=" + appId + "&schedulerList=" + schedulerList)
            .then((res) => {
                if (res) {
                    return Promise.resolve(res.data);
                } else {
                    let error = [ErrorConstants.RE_EXECUTE_ERR];
                    return Promise.reject(error);
                }
            })
            .catch((err) => {
                let error = [ErrorConstants.RE_EXECUTE_ERR];
                return Promise.reject(error);
            })
    );
}

function fetchReExecuteAppDetails() {
    AuthService.setupAxiosInterceptor();
    return (
        axios
            .post(`${APIConstants.FETCH_REEXECUTE_APP_DETAILS}`)
            .then((res) => {
                if (res) {
                    return Promise.resolve(res.data);
                } else {
                    let error = [ErrorConstants.RE_EXECUTE_FETCH_APPS_ERR];
                    return Promise.reject(error);
                }
            })
            .catch((err) => {
                let error = [ErrorConstants.RE_EXECUTE_FETCH_APPS_ERR];
                return Promise.reject(error);
            })
    );
}

function getLogs(appName, logEnv) {
    AuthService.setupAxiosInterceptor();
    return (
        axios
            .post(`${url && url.logEnvironment == "eks" ?
                APIConstants.GET_LOGS_EKS + "?appName=" + appName + "&logCat=" + logEnv :
                APIConstants.GET_LOGS + "?appName=" + appName}`)
            .then((res) => {
                if (res) {
                    return Promise.resolve(res.data);
                } else {
                    let error = [ErrorConstants.GET_LOGS_ERR];
                    return Promise.reject(error);
                }
            })
            .catch((err) => {
                let error = [ErrorConstants.GET_LOGS_ERR];
                return Promise.reject(error);
            })
    );
}

function downloadLogFile(appName, branchName, fileName, logEnv) {
    AuthService.setupAxiosInterceptor();
    return (
        axios
            .post(`${url && url.logEnvironment == "eks" ?
                APIConstants.DOWNLOAD_LOG_FILE_EKS + "?appName=" + appName + "&branchName=" + branchName + "&fileName=" + fileName + "&logCat=" + logEnv :
                APIConstants.DOWNLOAD_LOG_FILE + "?appName=" + appName + "&branchName=" + branchName + "&fileName=" + fileName}`)
            .then((res) => {
                if (res) {
                    return Promise.resolve(res.data);
                } else {
                    let error = [ErrorConstants.GET_LOGS_ERR];
                    return Promise.reject(error);
                }
            })
            .catch((err) => {
                let error = [ErrorConstants.GET_LOGS_ERR];
                return Promise.reject(error);
            })
    );
}
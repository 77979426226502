import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Chart } from "primereact/chart";

class SchedulerChart extends Component {
    constructor(props) {
        super(props);
        this.lightOptions = {
            legend: {
                labels: {
                    fontColor: "#495057",
                },
            },
        };
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <Chart className="totalchart"
                        type="doughnut"
                        data={this.props.data}
                        options={this.lightOptions}
                    />
                    <div>
                        <div style={{ marginTop: "3%", backgroundColor: "#e0ebff" }}>
                            {this.props.totalPercent ?
                                parseFloat(this.props.totalPercent).toFixed(2) : ""
                            }{"%"} Completed Successfully</div>

                        <div style={{ fontSize: "12px", marginTop: "4%" }}>
                            <h3>Details </h3>
                            <ul style={{ marginLeft: "8%" }}>
                                <li><label>{"Start:"}</label><b style={{ marginLeft: "10px" }}>{this.props.starttime}</b> </li><hr style={{ borderTop: "2px dotted #bbb" }} />
                                <li><label>{"End:"}</label><b style={{ marginLeft: "14px" }}>{this.props.endtime}</b> </li><hr style={{ borderTop: "2px dotted #bbb" }} />
                                <li><label>{"Duration:"}</label><b style={{ marginLeft: "22px" }}>{this.props.totalDuration}</b></li><hr style={{ borderTop: "2px dotted #bbb" }} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation("translations")(SchedulerChart);
import React, { Component} from 'react';
import classNames from 'classnames';
import { AuthService } from "../../../service/AuthService";
import { withTranslation } from "react-i18next";

class CasLoginUserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    render() {
        const images = require.context('../../../assets/images', true);
        const  {t} = this.props;
        //let imgsrc = images(`./${AuthService.getUsername()}.jpg`);
        //let name = this.props.i18n.language==="en" ? AuthService.getName() : AuthService.getJpName();
        let imgsrc = images(`./n00121.jpg`);
        let name = this.props.i18n.language==="en" ? "n00121" : AuthService.getJpName();
       return  (
            <div className="layout-profile">
                <div>
                    <img src={imgsrc} alt="" />
                </div>
                <button className="p-link layout-profile-link" onClick={this.onClick}>
                    <span className="username">{name}</span>
                    <i className={classNames({'pi pi-fw pi-chevron-down': !this.state.expanded, 'pi pi-fw pi-chevron-up': this.state.expanded})} />
                </button>
                <ul className={classNames({'layout-profile-expanded': this.state.expanded})}>
                    <li><button className="p-link"><i className="pi pi-fw pi-user"/><span>{t("account")}</span></button></li>
                    <li><button className="p-link"><i className="pi pi-fw pi-inbox"/><span>{t("noti")}</span></button></li>
                   
                </ul>
            </div>
        );
    }
}

export default withTranslation("translations")(CasLoginUserProfile);

import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CustomTranslate from "../translate/CustomTranslate";
import { AuthService } from "../../../service/AuthService";

class CasTopbar extends Component {
  constructor(props){
    super(props);
    this.state={
      isEng : true
    }
    this.logOutAction = this.logOutAction.bind(this);
  }
  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

 

  static defaultProps = {
    onToggleMenu: () => {},
  };

  logOutAction(){
    if(this.props.onLogOut){
      this.props.onLogOut();
    }
  }


  render() {
    let menuToggleClassName = "pi pi-chevron-left";
    if (this.props.staticMenuInactive) {
      menuToggleClassName = "pi pi-chevron-right";
    }
    const {t} = this.props;
    let label = t("topBar",{returnObjects:true});
    return (
      <div>
        <div className="layout-topbar-left clearfix">
        
   
        <div  style={{cursor: 'pointer'}}>
            <a href="/home">
              <img
                alt="Card"
                src={require("../../../assets/images/rapidtest_dashboard_logo.png")}
                className="dashboard-layout-logo"
              />
            </a>

          </div>
          {/* <div  className="toggeleBtn">
          <button
            className=" p-link layout-menu-button "
            onClick={this.props.onToggleMenu}
          >
            <span className={menuToggleClassName} />
          </button>
          </div> */}
        </div>
        <div className="layout-topbar clearfix">
          <div className="layout-topbar-icons">
            {/* <span className="layout-topbar-search float-left">
              <InputText type="text" placeholder={label.search} />
              <span className="layout-topbar-search-icon pi pi-search" />
            </span> */}
            {/* <CustomTranslate className="translate-btn app-lang-btn" /> */}
           

            <span className="dropdown float-left">
              <button className="p-link dropbtn">
                <span className="layout-topbar-item-text" >{localStorage.getItem('displayName')}</span>
                <span className="layout-topbar-search-icon pi pi-user" />
              </button>
      
              <div className="dropdown-content">
              <li><button className="p-link"><i className="pi pi-fw pi-user"/><span>{label.profile}</span></button></li>
                    
              <li><button className="p-link" onClick={this.logOutAction}><i className="pi pi-fw pi-power-off"/><span>{label.logOut}</span></button></li>
              </div>
            </span>
            
           
          </div>
        </div>
      </div>
    );
  }
}

export default  withTranslation("translations")(CasTopbar);
